import {
    useBaseAppContext,
    useAuthContext,
} from "@programari-limit/base-react";
import Axios from "axios";
import { envVariable } from "@programari-limit/base-react";

const useRequest = () => {
    const { authToken } = useAuthContext();
    const { currentLanguage } = useBaseAppContext();

    const getInformes = (route, params) => {
        return Axios.get(
            `${envVariable("REACT_APP_API_URL")}/${route}?${params}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Accept-Language": currentLanguage,
                },
            }
        );
    };
    const getEveretPropietats = () => {
        return Axios.get(
            `${envVariable("REACT_APP_API_URL")}/everet/propietats`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Accept-Language": currentLanguage,
                },
            }
        );
    };

    return { getInformes, getEveretPropietats };
};

export default useRequest;