import {
    Box,
    Button,
    CircularProgress,
    Icon,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import { useBaseAppContext } from "@programari-limit/base-react";
import useRequest from "hooks/useInformesRequest";
import React from "react";
import { useTranslation } from "react-i18next";

const Everet = () => {
    const { setContentExpandsToAvailableHeight } = useBaseAppContext();
    const { t } = useTranslation();
    const { getEveretPropietats } = useRequest();
    const [loading, setLoading] = React.useState(true);
    const [propietats, setPropietats] = React.useState();

    // Fer peti a "everet/pripietats" per obtenir la url (interfaceUrl) i l'estat (status)

    const getPropietats = () => {
        setLoading(true);
        getEveretPropietats().then((result) => {
            setPropietats(result?.data);
            setLoading(false);
        });
    };

    React.useEffect(() => {
        getPropietats();
    }, []);

    //TODO. Aquest useEffect es fa per solucionar un problema de la llibreria, dins GridCore L.723-729
    React.useEffect(() => {
        setContentExpandsToAvailableHeight(true);
        return () => setContentExpandsToAvailableHeight(false);
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    mb: 14,
                    p: 4,
                    borderRadius: 2,
                    maxWidth: 500,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 2,
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ textAlign: "center" }}
                >
                    {t("everet.title")}
                </Typography>
                {propietats && !loading ? (
                    <>
                        {/* <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                            {t("everet.versio")}:&nbsp;
                            <Typography
                                component={"span"}
                                sx={{ fontStyle: "italic" }}
                            >
                                resultats de petis
                            </Typography>
                        </Typography> */}
                        <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                            Url:&nbsp;
                            <Typography
                                component={"span"}
                                sx={{ fontStyle: "italic" }}
                            >
                                {propietats?.interfaceUrl}
                            </Typography>
                        </Typography>
                        <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                            {t("everet.estat")}:&nbsp;
                            <Typography
                                component={"span"}
                                sx={{ fontStyle: "italic" }}
                            >
                                {propietats?.status
                                    ? t("everet.actiu")
                                    : t("everet.inactiu")}
                            </Typography>
                        </Typography>
                        <Tooltip title={propietats?.interfaceUrl}>
                            <Button
                                onClick={() =>
                                    window.open(
                                        propietats?.interfaceUrl,
                                        "_blank",
                                        "noopener"
                                    )
                                }
                                color="primary"
                                variant="contained"
                                endIcon={<Icon>open_in_browser</Icon>}
                            >
                                {t("everet.anarEveret")}
                            </Button>
                        </Tooltip>
                    </>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default Everet;
