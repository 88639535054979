import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { suffixValueFormatter } from "utils/muiUtils";
import { columnesArticle } from "sharedAdvancedSearch/advancedSearchColumns";
import TwoLayerContainer from "components/TwoLayerContainer";
import { Box, Typography } from "@mui/material";
import ArticlesOpcions from "./ArticlesOpcions";
import { useBaseAppContext } from "@programari-limit/base-react";

const OpcionsForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="nom" xs={12} />
            <GridFormField
                name="article"
                xs={12}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth="lg"
            />
        </MuiGrid>
    );
};

const Opcions = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const gridApiRef = React.useRef();
    const { setContentExpandsToAvailableHeight } = useBaseAppContext();
    const [currentOpenOpcio, setCurrentOpenOpcio] = React.useState(); //Se usa para el componenteTwoLayerContainer

    const columns = [
        {
            name: "nom",
            flex: 1.5,
        },
    ];

    //Funcion para abrir y cerrar TwoLayerContainer
    const handleEditRequisitRecursButtonClick = (params) => {
        setCurrentOpenOpcio(params.row);
    };

    const rowAdditionalActions = (params) => {
        const listActions = [
            {
                icon: "library_books",
                label: t("articlesOpcions.title"),
                onClick: (params) =>
                    handleEditRequisitRecursButtonClick(params),
                showInMenu: false,
            },
        ];

        return listActions;
    };

    //TODO. Aquest useEffect es fa per solucionar un problema de la llibreria, dins GridCore L.723-729
    React.useEffect(() => {
        setContentExpandsToAvailableHeight(true);
        return () => setContentExpandsToAvailableHeight(false);
    }, [currentOpenOpcio]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <MuiGrid container spacing={2} sx={{ mt: 1, flexGrow: 1 }}>
                <MuiGrid
                    item
                    xs={5}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid
                            rowActionsColumnProps={{ width: 150 }}
                            apiRef={gridApiRef}
                            title={t("opcions.title")}
                            resourceName="opcio"
                            toolbarAdditionalRow={
                                <Typography variant="h6">
                                    {data?.codi + " - " + data?.nom}
                                </Typography>
                            }
                            columns={columns}
                            density="compact"
                            rowAdditionalActions={(params) =>
                                rowAdditionalActions(params)
                            }
                            fixedFilter={`article.id : ${data?.id}`}
                            inlineFormStaticData={{ article: { id: data?.id } }}
                            defaultSortModel={[
                                {
                                    field: "nom",
                                    sort: "asc",
                                },
                            ]}
                        />
                    </Box>
                </MuiGrid>
                <MuiGrid
                    item
                    xs={7}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    {currentOpenOpcio && (
                        <ArticlesOpcions
                            currentOpenOpcio={currentOpenOpcio}
                            setCurrentOpenOpcio={setCurrentOpenOpcio}
                        />
                    )}
                </MuiGrid>
            </MuiGrid>
        </Box>
    );
};
export default Opcions;
