import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form, Filter, useBaseAppContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Box, Divider, Icon, IconButton, Tooltip } from "@mui/material";
import { suffixValueFormatter } from "utils/muiUtils";
import GridFilterField from "components/GridFilterField";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { columnesZones } from "sharedAdvancedSearch/advancedSearchColumns";
import { likeAny } from "utils/springFilterUtils";

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="codi" lg={1.2} />
            <GridFilterField name="nom" lg={2} />
            <GridFilterField name="nomTicket" lg={2} />
            <GridFilterField
                name="zona"
                lg={2}
                advancedSearchColumns={columnesZones}
                advancedSearchMaxWidth={"sm"}
            />
            <GridFilterField name="tipus" lg={1.3} />
            <GridFilterField
                name="zonaDesti"
                lg={2}
                advancedSearchColumns={columnesZones}
                advancedSearchMaxWidth={"sm"}
            />
            <GridFilterField name="actiu" lg={1} type="checkbox-select" />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, ge, le, like } = SpringFilterQueryBuilder;

    const filter = and(
        like("codi", likeAny(data?.codi)),
        like("nom", likeAny(data?.nom)),
        like("nomTicket", likeAny(data?.nomTicket)),
        equal("zona.id", data?.zona?.id),
        equal("zonaDesti.id", data?.zonaDesti?.id),
        equal("tipus", data?.tipus),
        equal("actiu", data?.actiu?.toString())
    ).toString();

    return filter;
};

const ProducteFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"P_FILTER"}
                resourceName={"producte"}
                persistentState
                springFilterBuilder={springFilterBuilder}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

export const ProductesForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "client",
            flex: 3,
        },
        {
            name: "tipus",
            flex: 3,
        },
        {
            name: "preu",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Form
                resourceName="producte"
                id={id}
                titleCreate={t("productes.create")}
                titleUpdate={t("productes.update")}
                onGoBack={() => navigate(-1)}
                size="small"
            >
                <MuiGrid container spacing={2}>
                    <GridFormField name="codi" lg={2} />
                    <GridFormField name="nom" lg={8} />
                    <GridFormField name="tipus" lg={8} />
                    <GridFormField name="actiu" lg={2} />
                    <GridFormField name="zona" lg={4} />
                    <GridFormField name="descripcio" lg={12} />
                    <GridFormField name="observacions" lg={12} />
                </MuiGrid>
            </Form>
            <Divider sx={{ mt: 4, mb: 1 }} />
            {id ? (
                <Grid
                    title={t("productesClient.title")}
                    resourceName="producteClient"
                    columns={columns}
                    density={"compact"}
                    fixedFilter={`producte.id : '${id}'`}
                    inlineFormStaticData={{
                        producte: {
                            id: id,
                        },
                    }}
                />
            ) : undefined}
        </Box>
    );
};

const Productes = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const navigate = useNavigate();
    const gridApiRef = React.useRef();
    const { currentLanguage } = useBaseAppContext();

    const columns = [
        {
            name: "codi",
            flex: 1.2,
        },
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "nomTicket",
            flex: 2,
        },
        {
            name: "zona",
            flex: 1.5,
        },
        {
            name: "tipus",
            flex: 1.5,
        },
        {
            name: "zonaDesti",
            flex: 1.5,
        },
        {
            name: "actiu",
            flex: 1,
        },
        // {
        //     name: "descripcio",
        //     flex: 2.5,
        // },
    ];

    const rowAdditionalActions = (params) => {
        const listActions = [];

        listActions.push({
            icon: "content_copy",
            // label: "Duplicar producte",
            showInMenu: true,
            rowLinkAction: "P_DUPLICAR",
            rowLinkActionOnSuccess: (params) => navigate(`form/${params?.id}`),
            rowLinkFormStaticData: {
                nomProducte: params?.row?.nom + " (copia)",
                nomTicket: params?.row?.nomTicket + " (copia)",
            },
            rowLinkFormChildren: (
                <MuiGrid container spacing={2} sx={{ mt: 1 }}>
                    <GridFormField name="codiProducte" lg={4} />
                    <GridFormField name="nomProducte" lg={12} />
                    <GridFormField name="nomTicket" lg={12} />
                </MuiGrid>
            ),
        });

        return listActions;
    };

    // Todo: S'ha posat aixo pq la llibreria no refresca la traduccio de l'accio sense refrescar el grid
    React.useEffect(() => {
        gridApiRef?.current?.doRefresh();
    }, [currentLanguage]);


    return (
        <Grid
            apiRef={gridApiRef}
            title={t("productes.title")}
            resourceName="producte"
            columns={columns}
            formRoutePath="form"
            actionExecEnabled
            density="compact"
            fixedFilter={fixedFilter}
            findDisabled={!filterInitialized}
            toolbarAdditionalRow={
                <ProducteFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
            }
            rowAdditionalActions={(params) => rowAdditionalActions(params)}
        />
    );
};

export default Productes;
