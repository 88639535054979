import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MuiGrid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { Form, Grid, useFormContext } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    columnesPuntRuta,
    columnesUsuaris,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";
import RutaTripulant from "./RutesTripulant";
import { getFielYellow } from "pages/reserves/Reserves";

const PuntsRutaForm = () => {
    const { data } = useFormContext();
    return (
        <MuiGrid container spacing={2} sx={{ mt: 2 }}>
            <GridFormField
                name="zona"
                lg={8}
                advancedSearchColumns={columnesZones}
                advancedSearchMaxWidth={"sm"}
                disabled={data?.id}
            />
            <GridFormField name="hora" lg={4} />
            <GridFormField name="pugen" lg={4} />
            <GridFormField name="baixen" lg={4} />
            <GridFormField name="nous" lg={4} />
            <GridFormField name="totalNous" lg={4} />
            <GridFormField name="total" lg={8} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const Observacions = ({ row }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ backgroundColor: getFielYellow(), py: 1 }}>
            <Typography sx={{ mx: 1 }} variant="body2">
                <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="body2"
                    component={"span"}
                >
                    {t("rutes.tabs.punt.observacions")}:&nbsp;
                </Typography>
                {row.observacions}
            </Typography>
        </Box>
    );
};
const PuntsRuta = () => {
    const { data } = useFormContext();
    const { t } = useTranslation();
    const [expandedRowIds, setExpandedRowIds] = React.useState();

    const columns = [
        {
            name: "zona",
            flex: 1,
        },
        {
            name: "hora",
            flex: 0.5,
        },
        {
            name: "pugen",
            flex: 0.5,
        },
        {
            name: "baixen",
            flex: 0.5,
        },
        {
            name: "total",
            flex: 0.5,
        },
        {
            name: "nous",
            flex: 0.5,
        },
        {
            name: "totalNous",
            flex: 0.5,
        },
    ];
    return (
        <Grid
            title={t("rutes.tabs.punt.title")}
            resourceName="rutaPunt"
            columns={columns}
            fixedFilter={`ruta.id : ${data?.id}`}
            formPopupEditable
            formPopupComponent={<PuntsRutaForm />}
            formPopupTitleCreate={t("rutes.tabs.punt.create")}
            formPopupTitleUpdate={t("rutes.tabs.punt.update")}
            formPopupStaticData={{ ruta: { id: data?.id } }}
            density="compact"
            defaultSortModel={[
                {
                    field: "hora",
                    sort: "asc",
                },
            ]}
            onRefresh={(params) => {
                setExpandedRowIds(
                    params
                        .filter((row) => row.observacions)
                        .map((row) => row.id)
                );
            }}
            getDetailPanelContent={({ row }) => {
                if (!row.observacions) return null;
                return <Observacions row={row} />;
            }}
            getDetailPanelHeight={({ row }) => "auto"}
            detailPanelExpandedRowIds={expandedRowIds}
            onDetailPanelExpandedRowIdsChange={(newExpandedRowIds) =>
                setExpandedRowIds(newExpandedRowIds)
            }
        />
    );
};

const DiariDeBordForm = () => {
    const { data } = useFormContext();
    return (
        <MuiGrid container spacing={2} sx={{ mt: 2 }}>
            <GridFormField name="hora" lg={3} />
            <GridFormField
                name="rutaPunt"
                lg={4.5}
                advancedSearchColumns={columnesPuntRuta}
            />
            <GridFormField
                name="user"
                lg={4.5}
                advancedSearchColumns={columnesUsuaris}
            />
            <GridFormField name="descripcio" lg={12} />
        </MuiGrid>
    );
};

const DiariDeBord = () => {
    const { data } = useFormContext();
    const { t } = useTranslation();

    const columns = [
        {
            name: "hora",
            flex: 0.5,
        },
        {
            name: "rutaPunt",
            flex: 1,
        },
        {
            name: "user",
            flex: 1,
        },
        {
            name: "descripcio",
            flex: 1.5,
        },
    ];
    return (
        <Grid
            title={t("rutes.tabs.incidencia.title")}
            resourceName="rutaIncidencia"
            columns={columns}
            fixedFilter={`ruta.id : ${data?.id}`}
            formPopupEditable
            formPopupComponent={<DiariDeBordForm />}
            formPopupTitleCreate={t("rutes.tabs.incidencia.create")}
            formPopupTitleUpdate={t("rutes.tabs.incidencia.update")}
            formPopupSize="lg"
            formPopupStaticData={{ ruta: { id: data?.id } }}
            density="compact"
            defaultSortModel={[
                {
                    field: "hora",
                    sort: "desc",
                },
            ]}
        />
    );
};

const RutesForm = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="data" lg={2} />
            <GridFormField name="recurs" lg={3} />
            <GridFormField name="embarcacio" lg={3} />
            <GridFormField name="passatgeNouTotal" lg={2} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const RutesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("rutes.title")}
                >
                    <Tab
                        label={t(`rutes.${data?.id ? "update" : "create"}`)}
                        value="0"
                    />
                    <Tab
                        label={t("tripulants.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("rutes.tabs.punt.title")}
                        value={"2"}
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("rutes.tabs.incidencia.title")}
                        value={"3"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <RutesForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <RutaTripulant data={data} />
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", p: 0 }}>
                <PuntsRuta />
            </TabPanel>
            <TabPanel value="3" sx={{ height: "100%", p: 0 }}>
                <DiariDeBord />
            </TabPanel>
        </TabContext>
    );
};

const RutesDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="ruta"
                id={id}
                titleCreate={t("rutes.create")}
                titleUpdate={t("rutes.update")}
                size="small"
                fixedLockValue={true}
                onGoBack={() => navigate(id ? "../../" : "../")}
                onCreate={(data) => {
                    navigate(data.id.toString());
                }}
            >
                <RutesFormContent />
            </Form>
        </Box>
    );
};
export default RutesDetall;
