const translationCa = {
    comu: {
        si: "Si",
        no: "No",
        resetFiltre: "Esborrar filtre",
        detall: "Veure el detall",
        tancar: "Tancar",
        deselecionar: "Desseleccionar tot",
        noRegistres: 'No hi ha registres',
        refrescar: 'Refrescar',
    },
    page: {
        notFound: "No trobat",
    },
    clients: {
        title: "Clients",
        create: "Crear client",
        update: "Modificar client",
    },
    zones: {
        title: "Zones",
        create: "Crear zona",
        update: "Modificar zona",
    },
    zonesUser: {
        title: "Zones d'usuari",
        create: "Crear zona d'usuari",
        update: "Modificar zona d'usuari",
    },
    users: {
        title: "Usuaris",
        create: "Crear usuari",
        update: "Modificar usuari",
        password: {
            title: "Canviar contrasenya",
            caption: "Emplenar només si es vol modificar la contrasenya de l'usuari",
        },
    },
    recursos: {
        title: "Recursos",
        create: "Crear recurs",
        update: "Modificar recurs",
    },
    embarcacions: {
        title: "Embarcacions",
        create: "Crear embarcacio",
        update: "Modificar embarcacio",
    },
    reserves: {
        title: "Reserves",
        create: "Crear reserva",
        update: "Modificar reserva",
        totals: {
            title: "Totals",
            adults: "Adults",
            infants: "Infants",
            bebes: "Bebes",
            efectiu: "Efectiu",
            targeta: "Targeta",
            reserves: "Reserves",
            total: "Total",
        },
        exportarA3: "Descarregar export A3",
        reservaManual: "Aquesta reserva ha estat modificada manualment",
        liniaManual: "Alguna de les línies d'aquesta reserva han estat modificades manualment",
        reservaLiniaManual: "Tant aquesta reserva com alguna de les seves línies han estat modificades manualment",
        altres: 'Altres',
    },
    reservesDescompte: {
        title: "Descomptes de reserva",
        create: "Crear descomptes de reserva",
        update: "Modificar descomptes de reserva",
    },
    reservesLinia: {
        title: "Linies de reserva",
        create: "Crear linies de reserva",
        update: "Modificar linies de reserva",
    },
    requisitsRecurs: {
        title: "Requisits de recursos",
        create: "Crear requisits de recursos",
        update: "Modificar requisits de recursos",
    },
    requerimentsRecurs: {
        title: "Requeriments de recursos",
        create: "Crear requeriment de recursos",
        update: "Modificar requeriment de recursos",
    },
    productes: {
        title: "Productes",
        create: "Crear producte",
        update: "Modificar producte",
    },
    agendesProducte: {
        title: "Disponibilitat del producte",
        create: "Crear disponibilitat del producte",
        update: "Modificar disponibilitat del producte",
        tab: {
            taula: 'Taula',
            calendari: 'Calendari',
        },
        actions: {
            planificar: {
                title: 'Planificar',
                tabForm: 'Dades generals',
            },
            seleccionar: 'Seleccionar totes les disponibilitats',
        },
        detall: {
            title: 'Veure detall',
        },
        calendari: {
            switchProductes: "Filtrar per tots els productes",
        },
    },
    parametres: {
        title: 'Paràmetres',
        titleMobil: 'Preu Cuevas per a l\'aplicació mòbil',
        titleComptabilitat: 'Paràmetres per a comptabilitat',
    },
    informes: {
        title: 'Informes per zona i venedor',
        create: 'Crear informe',
        update: 'Modificar informe',
        totals: {
            efectiu: 'Efectiu',
            targeta: 'Targeta',
            tiquets: 'Tiquets',
            coves: 'Coves',
            total: 'Import total',
            resultats: 'Resultats',
        },
    },
    informesTripulacio: {
        title: 'Informe de tripulació per recurs i/o embarcació',
        patrons: 'Patrons',
        mecanics: 'Mecànics',
        tripulancio: 'Tripulació',
        marinerFotos: 'Mariner fotos',
        marinerBar: 'Mariner bar',
        columnes: {
            nom: 'Nom',
            dies: 'Dies',
            percentatge: 'Percentatge',
        },
    },
    informesEmbarcacio: {
        title: 'Informe de tripulació per embarcació',
        patrons: 'Patrons',
        mecanics: 'Mecànics',
        tripulancio: 'Tripulació',
        columnes: {
            codi: 'Codi',
            nom: 'Nom',
            dies: 'Dies',
            percentatge: 'Percentatge',
        },
    },
    grafics: {
        title: 'Ús de recursos',
        ocupacio: 'Ocupació per recurs i hora',
        alert: 'No hi ha una data seleccionada, si us plau selecciona una data.',
        refrescar: 'Refrescar',
    },
    proveidor: {
        title: 'Proveïdor',
        create: 'Crear proveïdor',
        update: 'Modificar proveïdor',
    },
    centreCost: {
        title: 'Centre de costos',
        create: 'Crear centre de cost',
        update: 'Modificar centre de cost',
    },
    rutes: {
        title: 'Rutes planificades',
        create: 'Crear ruta planificada',
        update: 'Modificar ruta planificada',
        rutesMassives: 'Creació de rutes a partir de disponibilitats',
        resultatsRutes: 'Resultats de la creació de rutes a partir de disponibilitats',
        tabs: {
            punt: {
                title:'Punts de la ruta',
                create: 'Crear punt',
                update: 'Modificar punt',
                observacions: 'Observacions',
            },
            usuari: {
                title: 'Tripulació',
                create: 'Crear tripulant',
                update: 'Modificar tripulant',
            },
            incidencia: {
                title: 'Incidències',
                create: 'Crear incidencia',
                update: 'Modificar incidencia',
            },
        },
    },
    series: {
        title: 'Sèries',
        create: 'Crear una sèrie',
        update: 'Modificar la sèrie',
    },
    terminals: {
        title: 'Terminals',
        create: 'Crear un terminal',
        update: 'Modificar un terminal',
    },
    tripulants: {
        title: 'Tripulants',
        create: 'Crear un tripulant',
        update: 'Modificar un tripulant',
    },
    tripulantRuta: {
        title: 'Ruta-Tripulant',
        create: 'Crear Ruta-Tripulant',
        update: 'Modificar Ruta-Tripulant',
    },
    articles: {
        title: 'Articles',
        create: 'Crear article',
        update: 'Modificar article',
    },
    categories: {
        title: 'Categories',
        create: 'Crear categoria',
        update: 'Modificar categoria',
    },
    articleExistencies: {
        title: 'Existències',
        create: 'Crear existència',
        update: 'Modificar existència',
        action: {
            operacioCompra: {
                quantitat: 'Quantitat',
                quantitatPlaceHolder: 'Quantitat d\'articles comprats',
            },
            operacioInventari: {
                quantitat: 'Quantitat',
                quantitatPlaceHolder: 'Quantitat d\'articles',
            },
            operacioTraspas: {
                quantitat: 'Quantitat',
                quantitatPlaceHolder: 'Quantitat d\'articles a traspasar',
            }
        },
    },
    everet: {
        title: 'Everet',
        versio: 'Versió',
        estat: 'Estat',
        anarEveret: 'Anar a Everet',
        actiu: 'Actiu',
        inactiu: 'Inactiu',
    },
    operacio: {
        title: 'Operacions',
        create: 'Crear operació',
        update: 'Modificar operació',
        totals: {
            title: "Totals",
            efectiu: "Efectiu",
            targeta: "Targeta",
            operacions: "Operacions",
            total: "Total",
        },
        linies: {
            title: 'Línies d\'operació',
            create: 'Crear línia d\'operació',
            update: 'Modificar línia d\'operació',
            liniesArticle: {
                title: 'Articles de les línies',
                create: 'Crear article de les línies',
                update: 'Modificar article de les línies',
            },
        },
        action: {
            campAbonament: 'Quantitat a abonar',
            tooltipCampAbonament: 'Índica la quantitat d\'articles que vols abonar',
        },
    },
    opcions: {
        title: 'Opcions',
        create: 'Crear una opció',
        update: 'Modificar una opció',
    },
    articlesOpcions: {
        title: 'Articles / opcions',
        create: 'Crear article / opció',
        update: 'Modificar article / opció',
        opcioSeleccionada: 'Articles seleccionables per a l\'opció',
        tooltipTancarOpcio: 'Tancar opció seleccionada',
    },
};

export default translationCa;
