import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { columnesArticle } from "sharedAdvancedSearch/advancedSearchColumns";
import { Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";

const ArticlesOpcionsForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField
                name="article"
                xs={12}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth="lg"
                fixedFilter="combinacioArticles : false"
            />
            <GridFormField name="quantitat" xs={12} />
        </MuiGrid>
    );
};

const ArticlesOpcions = ({ currentOpenOpcio, setCurrentOpenOpcio }) => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "article",
            flex: 1.5,
        },
        {
            name: "quantitat",
            flex: 1,
        },
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Grid
                density="compact"
                // title={t("articlesOpcions.title")}
                resourceName="articleOpcio"
                columns={columns}
                toolbarAdditionalRow={
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6">
                            {`${t("articlesOpcions.opcioSeleccionada")}: ${
                                currentOpenOpcio?.nom
                            }`}
                        </Typography>
                    </Box>
                }
                toolbarAdditionalActions={[
                    {
                        position: 8,
                        component: (
                            <Tooltip
                                title={t("articlesOpcions.tooltipTancarOpcio")}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        setCurrentOpenOpcio(undefined)
                                    }
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                ]}
                formPopupEditable
                formPopupComponent={<ArticlesOpcionsForm />}
                formPopupSize="sm"
                formPopupTitleCreate={t("articlesOpcions.create")}
                formPopupTitleUpdate={t("articlesOpcions.update")}
                formPopupStaticData={{
                    opcio: { id: currentOpenOpcio?.id },
                }}
                inlineFormStaticData={{
                    opcio: { id: currentOpenOpcio?.id },
                }}
                fixedFilter={`opcio.id : ${currentOpenOpcio?.id}`}
                defaultSortModel={[
                    {
                        field: "article",
                        sort: "asc",
                    },
                ]}
            />
        </Box>
    );
};
export default ArticlesOpcions;
