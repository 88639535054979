const translationEn = {
    comu: {
        si: "Yes",
        no: "No",
        resetFiltre: "Delete filter",
        detall: "See detail",
        tancar: "Close",
        deselecionar: "Deselect"
    },
    page: {
        notFound: "Not found",
    },
    clients: {
        title: "Clients",
        create: "Create client",
        update: "Update client",
    },
    zones: {
        title: "Zones",
        create: "Create zone",
        update: "Update zone",
    },
    zonesUser: {
        title: "User zones",
        create: "Create user zone",
        update: "Update user zone",
    },
    reserves: {
        title: "Bookings",
        create: "Create booking",
        update: "Update booking",
        totals: {
            title: "Totals",
            adults: "Adults",
            infants: "Children",
            bebes: "Babies",
            efectiu: "Cash",
            targeta: "Card",
            reserves: "Reservations",
            total: "Total",
        },
        exportarA3: "Download export A3",
        reservaManual: "This reservation has been manually modified",
        liniaManual: "Some of the lines in this reservation have been manually modified.",
        reservaLiniaManual: "Both this reservation and some of its lines have been manually modified.",
        altres: 'Others',
    },
    recursos: {
        title: "Resources",
        create: "Create resource",
        update: "Update resource",
    },
    embarcacions: {
        title: "Boats",
        create: "Create boat",
        update: "Update boat",
    },
    reservesDescompte: {
        title: "Booking sales",
        create: "Create booking sales",
        update: "Update booking sales",
    },
    reservesLinia: {
        title: "Booking lines",
        create: "Create booking lines",
        update: "Update booking lines"
    },
    requisitsRecurs: {
        title: "Resource requirements",
        create: "Create resource requirements",
        update: "Modify resource requirements",
    },
    requerimentsRecurs: {
        title: "Resource requeriments",
        create: "Create resource requeriment",
        update: "Update resource requeriment",
    },
    users: {
        title: "Users",
        create: "Create user",
        update: "Update user",
        password: {
            title: "Change password",
            caption: "Complete only if you want to change the user's password",
        },
    },
    productes: {
        title: "Products",
        create: "Create product",
        update: "Update product",
    },
    agendesProducte: {
        title: "Product availability",
        create: "Create product availability",
        update: "Update product availability",
        tab: {
            taula: 'Table',
            calendari: 'Calendar',
        },
        actions: {
            planificar: {
                title: 'Plan',
                tabForm: 'General data',
            },
            seleccionar: 'Select all availabilities',
        },
        detall: {
            title: 'See detail',
        },
        calendari: {
            switchProductes: "Filter by all products",
        },
    },
    parametres: {
        title: 'Parameters',
        titleMobil: 'Cuevas price for mobile application',
        titleComptabilitat: 'Accounting parameters',
    },
    informes: {
        title: 'Reports by zone and salesperson',
        create: 'Create report',
        update: 'Modify report',
        totals: {
            efectiu: 'Cash',
            targeta: 'Card',
            tiquets: 'Tickets',
            coves: 'Caves',
            total: 'Total amount',
            resultats: 'Results',
        },
    },
    grafics: {
        title: 'Use of resources',
        ocupacio: 'Occupancy by resource and time',
        alert: 'No date selected, please select a date.',
        refrescar: 'Refresh',
    },
};

export default translationEn;
