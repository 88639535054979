import React from "react";
import { useTranslation } from "react-i18next";
import { Filter, Grid } from "@programari-limit/base-react";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { likeAny } from "utils/springFilterUtils";
import MuiGrid from "@mui/material/Grid";
import GridFilterField from "components/GridFilterField";

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="nom" sm={7} md={5} lg={4} xl={4.5} />
            <GridFilterField name="nif" sm={5} md={3} lg={2} xl={2} />
            <GridFilterField
                name="correu_electronic"
                sm={6}
                md={4}
                lg={3}
                xl={3}
            />
            <GridFilterField
                name="telefon"
                xs={10}
                sm={4.5}
                md={4}
                lg={2.5}
                xl={2}
            />

            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const ProveidorFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized, setExportFixedFilter } =
        props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"PRV_FILTER"}
                resourceName={"proveidor"}
                persistentState
                springFilterBuilder={(data) =>
                    springFilterBuilder(data, setExportFixedFilter)
                }
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, like } = SpringFilterQueryBuilder;

    const filter = and(
        like("nom", likeAny(data?.nom)),
        like("nif", likeAny(data?.nif)),
        like("correu_electronic", likeAny(data?.correu_electronic)),
        like("telefon", likeAny(data?.telefon))
    ).toString();

    return filter;
};

export const Proveidor = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const columns = [
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "tipus",
            flex: 1,
        },
        {
            name: "estat",
            flex: 1,
        },
        {
            name: "nif",
            flex: 1,
        },
        {
            name: "correu_electronic",
            flex: 2,
        },
        {
            name: "telefon",
            flex: 1,
        },
        {
            name: "poblacio",
            flex: 1,
        },
        {
            name: "observacions",
            flex: 2.5,
        },
    ];

    return (
        <Grid
            title={t("proveidor.title")}
            resourceName="proveidor"
            columns={columns}
            findDisabled={!filterInitialized}
            fixedFilter={fixedFilter}
            formRoutePath="form"
            toolbarAdditionalRow={
                <ProveidorFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
            }
            defaultSortModel={[
                {
                    field: "nom",
                    sort: "desc",
                },
            ]}
        />
    );
};

export default Proveidor;
