const translationEs = {
    comu: {
        si: "Si",
        no: "No",
        resetFiltre: "Borrar filtro",
        detall: "Ver el detalle",
        tancar: "Cerrar",
        deselecionar: "Deseleccionar todo",
        noRegistres: 'No hay registros',
        refrescar: 'Refrescar',
    },
    page: {
        notFound: "No encontrado",
    },
    clients: {
        title: "Clientes",
        create: "Crear cliente",
        update: "Modificar cliente",
    },
    zones: {
        title: "Zonas",
        create: "Crear zona",
        update: "Modificar zona",
    },
    zonesUser: {
        title: "Zonas de usuario",
        create: "Crear zona de usuario",
        update: "Modificar zona de usuario",
    },
    reserves: {
        title: "Reservas",
        create: "Crear reserva",
        update: "Modificar reserva",
        totals: {
            title: "Totales",
            adults: "Adultos",
            infants: "Niños",
            bebes: "Bebes",
            efectiu: "Efectivo",
            targeta: "Tarjeta",
            reserves: "Reservas",
            total: "Total",
        },
        exportarA3: "Descargar export A3",
        reservaManual: "Esta reserva ha sido modificada manualmente",
        liniaManual: "Alguna de las líneas de esta reserva han sido modificadas manualmente",
        reservaLiniaManual: "Tanto esta reserva como alguna de sus líneas han sido modificadas manualmente",
        altres: 'Otros',
    },
    recursos: {
        title: "Recursos",
        create: "Crear recurso",
        update: "Modificar recurso",
    },
    embarcacions: {
        title: "Embarcaciones",
        create: "Crear embarcación",
        update: "Modificar embarcación",
    },
    reservesDescompte: {
        title: "Descuentos de reserva",
        create: "Crear descuentos de reserva",
        update: "Modificar descuentos de reserva",
    },
    reservesLinia: {
        title: "Linias de reserva",
        create: "Crear linias de reserva",
        update: "Modificar linias de reserva",
    },
    requisitsRecurs: {
        title: "Requisitos de recursos",
        create: "Crear requisitos de recursos",
        update: "Modificar requisitos de recursos",
    },
    requerimentsRecurs: {
        title: "Requerimientos de recursos",
        create: "Crear requerimiento de recursos",
        update: "Modificar requerimiento de recursos",
    },
    users: {
        title: "Usuarios",
        create: "Crear usuario",
        update: "Modificar usuario",
        password: {
            title: "Cambiar contraseña",
            caption: "Rellenar sólo si se quiere modificar la contraseña del usuario",
        },
    },
    productes: {
        title: "Productos",
        create: "Crear producto",
        update: "Modificar producto",
    },
    agendesProducte: {
        title: "Disponibilidad del producto",
        create: "Crear disponibilidad del producto",
        update: "Modificar disponibilidad del producto",
        tab: {
            taula: 'Lista',
            calendari: 'Calendario',
        },
        actions: {
            planificar: {
                title: 'Planificar',
                tabForm: 'Datos generales',
            },
            seleccionar: 'Seleccionar todas las disponibilidades',
        },
        detall: {
            title: 'Ver detalle',
        },
        calendari: {
            switchProductes: "Filtrar por todos los productos",
        },
    },
    parametres: {
        title: 'Parámetros',
        titleMobil: 'Precio Cuevas para la aplicación móvil',
        titleComptabilitat: 'Parámetros para contabilidad',
    },
    informes: {
        title: 'Informes por zona y vendedor',
        create: 'Crear informe',
        update: 'Modificar informe',
        totals: {
            efectiu: 'Efectivo',
            targeta: 'Tarjeta',
            tiquets: 'Tiques',
            coves: 'Cuevas',
            total: 'Importe total',
            resultats: 'Resultados',
        },
    },
    informesTripulacio: {
        title: 'Informe de tripulación por recurso y/o embarcación',
        patrons: 'Patrones',
        mecanics: 'Mecánicos',
        tripulancio: 'Tripulación',
        marinerFotos: 'Marinero fotos',
        marinerBar: 'Marinero bar',
        columnes: {
            nom: 'Nombre',
            dies: 'Días',
            percentatge: 'Porcentaje',
        },
    },
    informesEmbarcacio: {
        title: 'Informe de tripulación por embarcación',
        patrons: 'Patrones',
        mecanics: 'Mecánicos',
        tripulancio: 'Tripulación',
        columnes: {
            codi: 'Codigo',
            nom: 'Nombre',
            dies: 'Días',
            percentatge: 'Porcentaje',
        },
    },
    grafics: {
        title: 'Uso de recursos',
        ocupacio: 'Ocupación por recurso y hora',
        alert: 'No hay una fecha seleccionada, por favor selecciona una fecha.',
        refrescar: 'Refrescar',
    },
    proveidor: {
        title: 'Proveedor',
        create: 'Crear proveedor',
        update: 'Modificar proveedor',
    },
    centreCost: {
        title: 'Centro de costes',
        create: 'Crear centro de coste',
        update: 'Modificar centro de coste',
    },
    rutes: {
        title: 'Rutas planificadas',
        create: 'Crear ruta planificada',
        update: 'Modificar ruta planificada',
        rutesMassives: 'Creación de rutas a partir de disponibilidades',
        resultatsRutes: 'Resultados de la creación de rutas a partir de disponibilidades',
        tabs: {
            punt: {
                title:'Puntos de la ruta',
                create: 'Crear punto',
                update: 'Modificar punto',
                observacions: 'Observaciones',
            },
            usuari: {
                title: 'Tripulación',
                create: 'Crear tripulante',
                update: 'Modificar tripulante',
            },
            incidencia: {
                title: 'Incidencias',
                create: 'Crear incidencia',
                update: 'Modificar incidencia',
            },
        },
    },
    series: {
        title: 'Series',
        create: 'Crear una serie',
        update: 'Modificar la serie',
    },
    terminals: {
        title: 'Terminales',
        create: 'Crear un terminal',
        update: 'Modificar un terminal',
    },
    tripulants: {
        title: 'Tripulantes',
        create: 'Crear un tripulante',
        update: 'Modificar un tripulante',
    },
    tripulantRuta: {
        title: 'Ruta-Tripulante',
        create: 'Crear Ruta-Tripulante',
        update: 'Modificar Ruta-Tripulante',
    },
    articles: {
        title: 'Artículos',
        create: 'Crear artículo',
        update: 'Modificar artículo',
    },
    categories: {
        title: 'Categorías',
        create: 'Crear categoría',
        update: 'Modificar categoría',
    },
    articleExistencies: {
        title: 'Existencias',
        create: 'Crear existencia',
        update: 'Modificar existencia',
        action: {
            operacioCompra: {
                quantitat: 'Cantidad',
                quantitatPlaceHolder: 'Cantidad de artículos comprados',
            },
            operacioInventari: {
                quantitat: 'Cantidad',
                quantitatPlaceHolder: 'Cantidad de artículos',
            },
            operacioTraspas: {
                quantitat: 'Cantidad',
                quantitatPlaceHolder: 'Cantidad de artículos a traspasar',
            },
        },
    },
    everet: {
        title: 'Everet',
        versio: 'Versión',
        estat: 'Estado',
        anarEveret: 'Ir a Everet',
        actiu: 'Activo',
        inactiu: 'Inactivo',
    },
    operacio: {
        title: 'Operaciones',
        create: 'Crear operación',
        update: 'Modificar operación',
        totals: {
            title: "Totales",
            efectiu: "Efectivo",
            targeta: "Tarjeta",
            operacions: "Operaciones",
            total: "Total",
        },
        linies: {
            title: 'Líneas de operación',
            create: 'Crear línea de operación',
            update: 'Modificar línea de operación',
            liniesArticle: {
                title: 'Artículos de las líneas',
                create: 'Crear artículo de las líneas',
                update: 'Modificar artículo de las líneas',
            },
        },
        action: {
            campAbonament: 'Cantidad a reembolsar',
            tooltipCampAbonament: 'Índica la cantidad de artículos que quieres reembolsar',
        },
    },
    opcions: {
        title: 'Opciones',
        create: 'Crear una opción',
        update: 'Modificar una opción',
    },
    articlesOpcions: {
        title: 'Artículos / opciones',
        create: 'Crear artículo / opción',
        update: 'Modificar artículo / opción',
        opcioSeleccionada: 'Artículos seleccionables para la opción',
        tooltipTancarOpcio: 'Cerrar opción seleccionada',
    },
};

export default translationEs;
