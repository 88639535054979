import React from "react";
import { Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import useRequest from "hooks/useInformesRequest";
import TaulaInformes from "components/TaulaInformes";
import { useTranslation } from "react-i18next";
import GridFilterField from "components/GridFilterField";
import MuiGrid from "@mui/material/Grid";
import { Filter } from "@programari-limit/base-react";
import { useBaseAppContext } from "@programari-limit/base-react";

const FilterContent = (props) => {
    const { filterApiRef, getTripulants, filter } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFilterField name="dataInici" xs={6} md={4} lg={2} />
            <GridFilterField name="dataFi" xs={6} md={4} lg={2} />
            <GridFilterField name="recurs" xs={6} md={4} lg={3} />
            <GridFilterField name="embarcacio" xs={6} md={4} lg={3} />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.refrescar")}>
                    <span>
                        <IconButton
                            onClick={() => getTripulants()}
                            disabled={!filter}
                        >
                            <Icon>refresh</Icon>
                        </IconButton>
                    </span>
                </Tooltip>
            </MuiGrid>
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const FiltreInformes = (props) => {
    const { onSpringFilterChange, onFilterInitialized, getTripulants, filter } =
        props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"RT_INFORME_TRIPULACIO_PER_RECURS_I_DATA"}
                resourceName={"ruta"}
                // persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent
                    filterApiRef={filterApiRef}
                    getTripulants={getTripulants}
                    filter={filter}
                />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    let filter = `dataInici=${data?.dataInici}&dataFi=${data?.dataFi}`;

    if (
        data?.dataInici &&
        data?.dataFi &&
        (data?.recurs?.id || data?.embarcacio?.id)
    ) {
        if (data?.recurs?.id) {
            filter += `&recursId=${data?.recurs?.id}`;
        }
        if (data?.embarcacio?.id) {
            filter += `&embarcacioId=${data?.embarcacio?.id}`;
        }
        return filter;
    } else {
        return undefined;
    }
};

const InformeTripulacio = () => {
    const { getInformes } = useRequest();
    const { setContentExpandsToAvailableHeight } = useBaseAppContext();

    const { t } = useTranslation();
    const [filter, setFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [patrons, setPatrons] = React.useState();
    const [mecanics, setMecanics] = React.useState();
    const [marinerFotos, setMarinerFotos] = React.useState();
    const [marinerBar, setMarinerBar] = React.useState();

    const paramsInformesTripulant = filter;

    const buidarEstats = () => {
        setPatrons(undefined);
        setMecanics(undefined);
        setMarinerFotos(undefined);
        setMarinerBar(undefined);
    };

    const getTripulants = () => {
        setLoading(true);
        buidarEstats();

        if (paramsInformesTripulant) {
            getInformes(
                "informesTripulacioRecurs/tripulants",
                paramsInformesTripulant
            ).then((data) => {
                data?.data.find((tripulant) => {
                    if (tripulant.rol === "patro")
                        setPatrons(tripulant.tripulants);
                    if (tripulant.rol === "mecanic")
                        setMecanics(tripulant.tripulants);
                    if (tripulant.rol === "marinerfotos")
                        setMarinerFotos(tripulant.tripulants);
                    if (tripulant.rol === "marinerbar")
                        setMarinerBar(tripulant.tripulants);
                    setLoading(false);
                });
            });
        }
    };

    React.useEffect(() => {
        getTripulants();
    }, [paramsInformesTripulant]);

    //TODO. Aquest useEffect es fa per solucionar un problema de la llibreria, dins GridCore L.723-729
    React.useEffect(() => {
        setContentExpandsToAvailableHeight(true);
        return () => setContentExpandsToAvailableHeight(false);
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
                pt: 2,
            }}
        >
            <Box>
                <Typography variant="h6">
                    {t("informesTripulacio.title")}
                </Typography>
                <FiltreInformes
                    onSpringFilterChange={setFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                    getTripulants={getTripulants}
                    filter={!!filter}
                />
            </Box>
            {filter && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        overflow: "hidden",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            overflow: "hidden",
                            p: 1,
                            height: "50%",
                        }}
                    >
                        <TaulaInformes
                            loading={loading}
                            content={patrons}
                            title={t("informesTripulacio.patrons")}
                        />
                        <TaulaInformes
                            loading={loading}
                            content={mecanics}
                            title={t("informesTripulacio.mecanics")}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            overflow: "hidden",
                            p: 1,
                            height: "50%",
                        }}
                    >
                        <TaulaInformes
                            loading={loading}
                            content={marinerFotos}
                            title={t("informesTripulacio.marinerFotos")}
                        />
                        <TaulaInformes
                            loading={loading}
                            content={marinerBar}
                            title={t("informesTripulacio.marinerBar")}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default InformeTripulacio;
