import React from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Filter,
    useBaseAppContext,
    useResourceApi,
    useFormContext,
} from "@programari-limit/base-react";
import {
    Box,
    Divider,
    darken,
    Icon,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import GridFilterField from "components/GridFilterField";
import MuiGrid from "@mui/material/Grid";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import {
    columnesArticle,
    columnesRuta,
    columnesUsuaris,
} from "sharedAdvancedSearch/advancedSearchColumns";
import GridFormField from "components/GridFormField";
import { getFieldHighlightRed, getFielYellow } from "pages/reserves/Reserves";
import { suffixValueFormatter } from "utils/muiUtils";
import CellEditComp2 from "components/CellEditComp2";

const ActionAbonarContent = (props) => {
    const { operacio } = props;
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "articleNom",
            flex: 1,
        },
        {
            name: "importRestant",
            flex: 0.75,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "preu",
            flex: 0.75,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "quantitatRestant",
            flex: 0.75,
        },
        {
            name: "devolucio",
            flex: 1,
            minWidth: 220,
            headerName: t("operacio.action.campAbonament"),
            renderCell: (params) => (
                <CellEditComp2
                    tootlip={t("operacio.action.tooltipCampAbonament")}
                    placeholder={t("operacio.action.campAbonament")}
                    id={params.id}
                    keyVal="quantitatsLinia"
                    max={params?.row?.quantitatRestant}
                    min={0}
                    step={0.001}
                />
            ),
        },
    ];
    return (
        <Box sx={{ height: "100%" }}>
            <MuiGrid container spacing={2} sx={{ my: 1 }}>
                <GridFormField
                    name="user"
                    lg={6}
                    advancedSearchColumns={columnesUsuaris}
                    advancedSearchMaxWidth="lg"
                    size="small"
                />
                <GridFormField name="tipusAbonamentForm" lg={3} size="small" />
                <GridFormField name="pagamentTipus" lg={3} size="small" />
                <GridFormField
                    name="importTargeta"
                    lg={3}
                    size="small"
                    disabled={data?.pagamentTipus === "EFECTIU"}
                    suffix={" €"}
                />
                <GridFormField
                    name="importEfectiu"
                    lg={3}
                    size="small"
                    disabled={data?.pagamentTipus === "TARGETA"}
                    suffix={" €"}
                />
                <GridFormField
                    name="importAbonament"
                    lg={3}
                    size="small"
                    suffix={" €"}
                    disabled
                />
                <GridFormField
                    name="importARetornar"
                    lg={3}
                    size="small"
                    suffix={" €"}
                    disabled
                />
            </MuiGrid>
            {data?.tipusAbonamentForm === "ABONAMENT_PARCIAL" && (
                <Grid
                    title={t("operacio.linies.title")}
                    resourceName="operacioLinia"
                    columns={columns}
                    readOnly
                    inlineEditable={false}
                    density="compact"
                    fixedFilter={`operacio.id : '${operacio?.id}'`}
                    height={300}
                />
            )}
        </Box>
    );
};

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="tipusOperacio" lg={2} />
            <GridFilterField name="dataInici" lg={1.5} />
            <GridFilterField name="dataFi" lg={1.5} />
            <GridFilterField
                name="article"
                lg={2}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFilterField
                name="ruta"
                lg={2.5}
                advancedSearchColumns={columnesRuta}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFilterField name="embarcacio" lg={2} />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const TotalsContent = (props) => {
    const { totals } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 5,
                mt: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("operacio.totals.operacions")}
                </Typography>
                <Typography>{totals.totalOperacions}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("operacio.totals.efectiu")}
                </Typography>
                <Typography>{totals.importEfectiu + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("operacio.totals.targeta")}
                </Typography>
                <Typography>{totals.importTargeta + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("operacio.totals.total")}
                </Typography>
                <Typography>{totals.importTotal + " €"}</Typography>
            </Box>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, ge, le, exists } = SpringFilterQueryBuilder;

    const filter = and(
        equal("tipusOperacio", data?.tipusOperacio),
        ge("data", data?.dataInici ? data?.dataInici + "T00:00:00" : undefined),
        le("data", data?.dataFi ? data?.dataFi + "T23:59:59" : undefined),
        equal("embarcacio.id", data?.embarcacio?.id),
        equal("ruta.id", data?.ruta?.id),
        data?.article &&
            exists(equal("liniesOperacio.article.id", data?.article?.id))
    ).toString();

    return filter;
};

const OperacioFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"O_FILTER"}
                resourceName={"operacio"}
                persistentState
                springFilterBuilder={springFilterBuilder}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialOnChangeRequest
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const Operacio = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const gridApiRef = React.useRef();
    const { currentLanguage } = useBaseAppContext();
    const { apiInitialized, exec: apiExec } = useResourceApi("operacio");
    const [totals, setTotals] = React.useState();

    const columns = [
        {
            name: "data",
            flex: 0.75,
            minWidth: 175,
        },
        {
            name: "tipusOperacio",
            flex: 1,
        },
        {
            name: "numero",
            flex: 0.75,
            minWidth: 150,
        },
        {
            name: "ruta",
            flex: 1,
            minWidth: 250,
        },
        {
            name: "embarcacio",
            flex: 1,
            minWidth: 150,
        },
        {
            name: "user",
            flex: 1,
        },
        {
            name: "numeroLinies",
            flex: 0.75,
        },
        {
            name: "importEfectiu",
            flex: 0.5,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importTargeta",
            flex: 0.5,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importTotal",
            flex: 0.5,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    const rowAdditionalActions = (params) => {
        const listActions = [
            {
                icon: "currency_exchange",
                showInMenu: true,
                rowLinkAction: "O_ABONAR",
                rowLinkActionOnSuccess: () => gridApiRef?.current?.doRefresh(),
                rowLinkFormChildren: <ActionAbonarContent operacio={params} />,
                rowLinkFormStaticData: { operacioId: params.id },
                rowLinkFormDialogSize: "lg",
                rowLinkFormInitialOnChangeRequest: true,
            },
        ];

        return listActions;
    };

    // Todo: S'ha posat aixo pq la llibreria no refresca la traduccio de l'accio sense refrescar el grid

    React.useEffect(() => {
        gridApiRef?.current?.doRefresh();
    }, [currentLanguage]);

    const obtenirTotals = () => {
        apiExec("exec_O_OBTENIR_TOTALS", {
            data: { springFilter: fixedFilter ?? undefined }, // TODO: Ha de poder rebre undefined com a filtre o text buid
            callbacks: {
                setResponse: (response) => {
                    const resource = {
                        ...response.data,
                        _resource: response,
                        _links: response.links,
                    };
                    setTotals(resource);
                },
                setError: (error) => {},
            },
        });
    };

    React.useEffect(() => {
        if (apiInitialized && filterInitialized) {
            obtenirTotals();
        }
    }, [apiInitialized, filterInitialized, fixedFilter]);

    return (
        <Box
            sx={{
                height: "100%",
                "& .cellAbonat": {
                    bgcolor: (theme) => getFielYellow(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellAbonat": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFielYellow(theme.palette.mode),
                            0.05
                        )}!important`,
                },
                "& .cellAbonament": {
                    bgcolor: (theme) =>
                        getFieldHighlightRed(theme.palette.mode),
                    color: (theme) => "#ab0000",
                },
                "& .MuiDataGrid-row:hover .cellAbonament": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFieldHighlightRed(theme.palette.mode),
                            0.05
                        )}!important`,
                },
            }}
        >
            <Grid
                apiRef={gridApiRef}
                title={t("operacio.title")}
                resourceName="operacio"
                fixedFilter={fixedFilter}
                findDisabled={!filterInitialized}
                actionExecEnabled
                onRefresh={() => obtenirTotals()}
                onDelete={() => obtenirTotals()}
                rowAdditionalActions={(params) =>
                    rowAdditionalActions(params.row)
                }
                columns={columns}
                formRoutePath="form"
                density="compact"
                toolbarAdditionalRow={
                    <>
                        <OperacioFilter
                            onSpringFilterChange={setFixedFilter}
                            onFilterInitialized={() =>
                                setFilterInitialized(true)
                            }
                        />
                        {totals && (
                            <Box sx={{ mb: 2 }}>
                                <TotalsContent totals={totals} />
                            </Box>
                        )}
                    </>
                }
                getCellClassName={({ row, field }) => {
                    // Pinta les caselles de les operacions que son abonaments totals/parcials
                    if (
                        row?.abonamentTipus === "ABONAMENT_TOTAL" ||
                        row?.abonamentTipus === "ABONAMENT_PARCIAL"
                    ) {
                        if (field === "importTotal" && row?.importTotal < 0) {
                            return "cellAbonament";
                        }
                        if (
                            field === "importEfectiu" &&
                            row?.importEfectiu < 0
                        ) {
                            return "cellAbonament";
                        }
                        if (
                            field === "importTargeta" &&
                            row?.importTargeta < 0
                        ) {
                            return "cellAbonament";
                        }
                    }

                    // Pinta la linia de l'operacio original que ha estat total/parcialment abonada
                    if (
                        row?.abonamentTipus === "OPERACIO_ABONADA_PARCIAL" ||
                        row?.abonamentTipus === "OPERACIO_ABONADA_TOTAL"
                    ) {
                        return "cellAbonat";
                    }

                    return "";
                }}
                defaultSortModel={[
                    {
                        field: "data",
                        sort: "desc",
                    },
                ]}
            />
        </Box>
    );
};

export default Operacio;
