import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { columnesArticle } from "sharedAdvancedSearch/advancedSearchColumns";
import { suffixValueFormatter } from "utils/muiUtils";

export const OperacioLiniaForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField
                name="article"
                lg={9}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFormField name="quantitat" lg={3} />
            <GridFormField name="quantitatAnterior" lg={4} />
            <GridFormField name="importLinia" lg={4} suffix={" €"} />
            <GridFormField name="preu" lg={4} suffix={" €"} />
        </MuiGrid>
    );
};

const OperacioLinia = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "articleNom",
            flex: 1,
        },
        {
            name: "importLinia",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "preu",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "quantitat",
            flex: 1,
        },
        {
            name: "quantitatAnterior",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("operacio.linies.title")}
            resourceName="operacioLinia"
            columns={columns}
            formPopupEditable
            formPopupComponent={<OperacioLiniaForm />}
            formPopupTitleCreate={t("operacio.linies.create")}
            formPopupTitleUpdate={t("operacio.linies.update")}
            // formPopupSize="lg"
            formPopupStaticData={{ operacio: { id: data?.id } }}
            fixedFilter={`operacio.id : '${data?.id}'`}
            density="compact"
        />
    );
};

export default OperacioLinia;
