import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { suffixValueFormatter } from "utils/muiUtils";

const Articles = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 0.5,
        },
        {
            name: "nom",
            flex: 1.25,
        },
        {
            name: "nomComercial",
            flex: 1,
        },
        {
            name: "articleCategoria",
            flex: 0.75,
        },
        {
            name: "iva",
            flex: 0.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " %",
            },
            valueFormatter: suffixValueFormatter,
        },
        {
            name: "preu",
            flex: 0.75,

            type: "currency",
            currencyCode: "EUR",
            currencyDecimalPlaces: 2,
        },
        {
            name: "controlExistencies",
            flex: 0.5,
        },
        {
            name: "ocult",
            flex: 0.5,
        },
        {
            name: "combinacioArticles",
            flex: 0.5,
        },
    ];

    return (
        <Grid
            density="compact"
            title={t("articles.title")}
            resourceName="article"
            columns={columns}
            formRoutePath="form"
            defaultSortModel={[
                {
                    field: "nom",
                    sort: "asc",
                },
            ]}
        />
    );
};
export default Articles;
