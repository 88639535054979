import React from "react";
import { Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import useRequest from "hooks/useInformesRequest";
import TaulaInformes from "components/TaulaInformes";
import { Filter, useBaseAppContext } from "@programari-limit/base-react";
import { useTranslation } from "react-i18next";
import MuiGrid from "@mui/material/Grid";
import GridFilterField from "components/GridFilterField";
import { columnesTripulant } from "sharedAdvancedSearch/advancedSearchColumns";

const FilterContent = (props) => {
    const { filterApiRef, getEmbarcacio, filter } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFilterField name="dataInici" xs={6} md={4} lg={2} />
            <GridFilterField name="dataFi" xs={6} md={4} lg={2} />
            <GridFilterField
                name="tripulant"
                xs={6}
                md={4}
                lg={3}
                advancedSearchColumns={columnesTripulant}
            />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.refrescar")}>
                    <span>
                        <IconButton
                            onClick={() => getEmbarcacio()}
                            disabled={!filter}
                        >
                            <Icon>refresh</Icon>
                        </IconButton>
                    </span>
                </Tooltip>
            </MuiGrid>
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const FiltreInformes = (props) => {
    const { onSpringFilterChange, onFilterInitialized, getEmbarcacio, filter } =
        props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"RT_INFORME_TRIPULACIO_PER_USUARI_I_DATA"}
                resourceName={"ruta"}
                // persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent
                    filterApiRef={filterApiRef}
                    getEmbarcacio={getEmbarcacio}
                    filter={filter}
                />
            </Filter>
        </Box>
    );
};
const springFilterBuilder = (data) => {
    const filter = `dataInici=${data?.dataInici}&dataFi=${data?.dataFi}&tripulantId=${data?.tripulant?.id}`;

    if (data?.dataInici && data?.dataFi && data?.tripulant?.id) {
        return filter;
    } else {
        return undefined;
    }
};

const InformeEmbarcacio = () => {
    const { getInformes } = useRequest();
    const { setContentExpandsToAvailableHeight } = useBaseAppContext();

    const { t } = useTranslation();
    const [filter, setFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [patrons, setPatrons] = React.useState();
    const [mecanics, setMecanics] = React.useState();
    const [marinerFotos, setMarinerFotos] = React.useState();
    const [marinerBar, setMarinerBar] = React.useState();

    const paramsInformesEmbarcacio = filter;

    const buidarEstats = () => {
        setPatrons(undefined);
        setMecanics(undefined);
        setMarinerFotos(undefined);
        setMarinerBar(undefined);
    };

    const getEmbarcacio = () => {
        setLoading(true);
        buidarEstats();

        if (paramsInformesEmbarcacio) {
            getInformes(
                "informesTripulacioRecurs/recursos",
                paramsInformesEmbarcacio
            ).then((data) => {
                data?.data.find((recurso) => {
                    if (recurso?.rol === "patro") setPatrons(recurso?.recursos);
                    if (recurso?.rol === "mecanic")
                        setMecanics(recurso?.recursos);
                    if (recurso.rol === "marinerfotos")
                        setMarinerFotos(recurso.recursos);
                    if (recurso.rol === "marinerbar") {
                        setMarinerBar(recurso.recursos);
                    }
                });
                setLoading(false);
            });
        }
    };

    React.useEffect(() => {
        getEmbarcacio();
    }, [paramsInformesEmbarcacio]);

    //TODO. Aquest useEffect es fa per solucionar un problema de la llibreria, dins GridCore L.723-729
    React.useEffect(() => {
        setContentExpandsToAvailableHeight(true);
        return () => setContentExpandsToAvailableHeight(false);
    }, []);

    const a = [
        {
            codi: "cre_venedor",
            nom: "Usuari",
            cognoms: "Venedor",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
        {
            codi: "danielm",
            nom: "Daniel",
            cognoms: "Moreno",
            dies: 1,
            tpc: 50,
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
                pt: 2,
            }}
        >
            <Box>
                <Typography variant="h6">
                    {t("informesEmbarcacio.title")}
                </Typography>
                <FiltreInformes
                    onSpringFilterChange={setFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                    getEmbarcacio={getEmbarcacio}
                    filter={!!filter}
                />
            </Box>
            {filter && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        overflow: "hidden",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            overflow: "hidden",
                            p: 1,
                            height: "50%",
                        }}
                    >
                        <TaulaInformes
                            loading={loading}
                            content={patrons}
                            title={t("informesTripulacio.patrons")}
                            isCodiTaula
                        />
                        <TaulaInformes
                            loading={loading}
                            content={mecanics}
                            title={t("informesTripulacio.mecanics")}
                            isCodiTaula
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            overflow: "hidden",
                            p: 1,
                            height: "50%",
                        }}
                    >
                        <TaulaInformes
                            loading={loading}
                            content={marinerFotos}
                            title={t("informesTripulacio.marinerFotos")}
                            isCodiTaula
                        />
                        <TaulaInformes
                            loading={loading}
                            content={marinerBar}
                            title={t("informesTripulacio.marinerBar")}
                            isCodiTaula
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default InformeEmbarcacio;
