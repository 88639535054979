import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MuiGrid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, useFormContext } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ZonesUser from "./ZonesUser";

const ZonesForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
            <MuiGrid container spacing={2} sx={{ mt: 1 }}>
                <GridFormField name="codi" lg={2} />
                <GridFormField name="nom" lg={10} />
                <GridFormField name="observacions" lg={12} />
            </MuiGrid>
    );
};

const ZonesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("zones.title")}
                >
                    <Tab label={t("zones.title")} value="0" />
                    <Tab
                        label={t("zonesUser.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <ZonesForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <ZonesUser zonaId={data?.id} />
            </TabPanel>
        </TabContext>
    );
};

const ZonesDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="zona"
                id={id}
                titleCreate={t("zones.create")}
                titleUpdate={t("zones.update")}
                onGoBack={() => navigate(-1)}
                size="small"
                // includeFormElement
            >
                <ZonesFormContent />
            </Form>
        </Box>
    );
};
export default ZonesDetall;
