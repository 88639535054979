import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";

export const RecursosForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Form
            resourceName="recurs"
            id={id}
            titleCreate={t("recursos.create")}
            titleUpdate={t("recursos.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="codi" lg={2} />
                <GridFormField name="nom" lg={4.5} />
                <GridFormField name="embarcacio" lg={4} />
                <GridFormField name="capacitat" lg={1.5} />
                <GridFormField name="observacions" lg={12} />
            </MuiGrid>
        </Form>
    );
};

const Recursos = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "embarcacio",
            flex: 2,
        },
        {
            name: "capacitat",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("recursos.title")}
            resourceName="recurs"
            columns={columns}
            formRoutePath="form"
        />
    );
};

export default Recursos;
