import * as React from "react";
import { Icon, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { FormField, useFormContext } from "@programari-limit/base-react";
import { useCallback, useEffect, useMemo } from "react";

const CellEditComp2 = (props) => {
    const {
        id,
        placeholder,
        keyVal = "selectedValues",
        min,
        max,
        step,
        tooltip,
    } = props;
    const { data, doFieldChange } = useFormContext();

    const dataRef = React.useRef(data);
    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    const onChange = useCallback(
        (value) => {
            const currentValue = dataRef.current?.[keyVal];
            const newVal = { ...currentValue, [id]: value };
            doFieldChange(keyVal, newVal);
        },
        [doFieldChange, id]
    );

    const value = data?.[keyVal]?.[id];

    return useMemo(
        () => (
            <FormField
                name={keyVal}
                type="number"
                variant="standard"
                hideLabel
                inline
                InputProps={{
                    placeholder: placeholder,
                    startAdornment: (
                        <Tooltip title={tooltip ?? placeholder}>
                            <InputAdornment position="start">
                                <Icon>add_comment</Icon>
                            </InputAdornment>
                        </Tooltip>
                    ),
                }}
                value={value ?? ""}
                onChange={(value) => {
                    onChange(value);
                }}
                min={min}
                max={max}
                step={step}
            />
        ),
        [onChange, value, min, max, step]
    );
};

export default CellEditComp2;
