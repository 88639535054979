import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { columnesArticle } from "sharedAdvancedSearch/advancedSearchColumns";

const OperacioLiniaArticleForm = () => {

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField
                name="article"
                xs={12}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField name="operacioLinia" xs={12} />
            <GridFormField name="quantitat" xs={12} />
        </MuiGrid>
    );
};

const OperacioLiniaArticle = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "operacioLinia",
            flex: 2,
        },
        {
            name: "article",
            flex: 2,
        },
        {
            name: "quantitat",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("operacio.linies.liniesArticle.title")}
            resourceName="operacioLiniaArticle"
            columns={columns}
            readOnly
            fixedFilter={`operacioLinia.operacio.id : ${data.id}`}
            density="compact"
            // formPopupEditable
            // formPopupComponent={<OperacioLiniaArticleForm />}
            // formPopupTitleCreate={t("operacio.linies.liniesArticle.create")}
            // formPopupTitleUpdate={t("operacio.linies.liniesArticle.update")}
            // formPopupSize="sm"
        />
    );
};
export default OperacioLiniaArticle;
