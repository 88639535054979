import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MuiGrid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Form, useFormContext } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    columnesRuta,
    columnesTripulant,
    columnesUsuaris,
} from "sharedAdvancedSearch/advancedSearchColumns";
import OperacioLinia from "./OperacioLinia";
import OperacioLiniaArticle from "pages/OperacioLiniaArticle";

const OperacioForm = () => {
    const { data } = useFormContext();
    return (
        <MuiGrid
            container
            spacing={2}
            sx={{
                mt: 2,
                "& .redText input": {
                    color: (theme) => "#d70000",
                },
            }}
        >
            <GridFormField name="data" lg={2} />
            <GridFormField name="tipusOperacio" lg={2} />
            <GridFormField name="numero" lg={2} />
            <GridFormField
                name="ruta"
                lg={3}
                advancedSearchColumns={columnesRuta}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFormField
                name="user"
                lg={3}
                advancedSearchColumns={columnesUsuaris}
            />
            <GridFormField name="embarcacio" lg={3} />
            <GridFormField name="embarcacioTraspas" lg={3} />
            <GridFormField name="numeroCompra" lg={3} />
            <GridFormField name="operacio" lg={3} />
            <GridFormField name="codiSerie" lg={2} />
            <GridFormField name="numeroSerie" lg={2} />
            <GridFormField name="descripcio" lg={12} />
            <GridFormField name="pagamentTipus" lg={2} />
            <GridFormField
                name="importEfectiu"
                lg={2}
                suffix={" €"}
                className={data?.importEfectiu < 0 ? "redText" : ""}
            />
            <GridFormField
                name="importTargeta"
                lg={2}
                suffix={" €"}
                className={data?.importTargeta < 0 ? "redText" : ""}
            />
            <GridFormField
                name="importTotal"
                lg={2}
                suffix={" €"}
                className={data?.importTotal < 0 ? "redText" : ""}
            />
            {data?.tipusOperacio === "CONSUM_TRIPULACIO" && (
                <>
                    <MuiGrid item xs={3} />
                    <GridFormField
                        name="consumidorTripulant"
                        lg={4}
                        advancedSearchColumns={columnesTripulant}
                    />
                </>
            )}
        </MuiGrid>
    );
};

const OperacioFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("operacio.title")}
                >
                    <Tab
                        label={t(`operacio.${data?.id ? "update" : "create"}`)}
                        value="0"
                    />
                    <Tab
                        label={t("operacio.linies.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("operacio.linies.liniesArticle.title")}
                        value={"2"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <OperacioForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <OperacioLinia />
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", p: 0 }}>
                <OperacioLiniaArticle />
            </TabPanel>
        </TabContext>
    );
};

const OperacioDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="operacio"
                id={id}
                titleCreate={t("operacio.create")} // Traduir
                titleUpdate={t("operacio.update")} // Traduir
                size="small"
                fixedLockValue={true}
                onGoBack={() => navigate(id ? "../../" : "../")}
                onCreate={(data) => {
                    navigate(data.id.toString());
                }}
            >
                <OperacioFormContent />
            </Form>
        </Box>
    );
};
export default OperacioDetall;
