import React from "react";
import { useTranslation } from "react-i18next";
import {
    ActionButton,
    Filter,
    Grid,
    useFormContext,
} from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import {
    columnesArticle,
    columnesRuta,
} from "sharedAdvancedSearch/advancedSearchColumns";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import GridFilterField from "components/GridFilterField";
import { likeAny } from "utils/springFilterUtils";
import CellEditComp2 from "components/CellEditComp2";
import { IconButtonButtonComponent } from "components/ActionReportButtonComponents";

// Accions de la taula
const OperacioTraspasContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const columns = [
        {
            name: "codi",
            flex: 0.4,
        },
        {
            name: "nom",
            flex: 0.8,
        },
        {
            name: "nomComercial",
            flex: 1,
        },
        {
            name: "articleCategoria",
            flex: 0.5,
        },
        {
            name: "devolucio",
            flex: 1,
            minWidth: 220,
            headerName: t(
                "articleExistencies.action.operacioTraspas.quantitat"
            ),
            renderCell: (params) => (
                <CellEditComp2
                    tootlip={t(
                        "articleExistencies.action.operacioTraspas.quantitat"
                    )}
                    placeholder={t(
                        "articleExistencies.action.operacioTraspas.quantitatPlaceHolder"
                    )}
                    id={params.id}
                    keyVal="quantitatsLinia"
                    max={99999999999}
                    min={0}
                    step={0.001}
                />
            ),
        },
    ];

    return (
        <Box sx={{ height: "100%" }}>
            <MuiGrid container spacing={2} sx={{ my: 1 }}>
                <GridFormField
                    name="ruta"
                    lg={4}
                    size="small"
                    advancedSearchColumns={columnesRuta}
                />
                <GridFormField
                    name="embarcacio"
                    lg={4}
                    size="small"
                    fixedFilter={
                        data?.embarcacioTraspas
                            ? `id ! ${data?.embarcacioTraspas?.id}`
                            : undefined
                    }
                />
                <GridFormField
                    name="embarcacioTraspas"
                    lg={4}
                    size="small"
                    fixedFilter={
                        data?.embarcacio
                            ? `id ! ${data?.embarcacio?.id}`
                            : undefined
                    }
                />

                <GridFormField name="descripcio" lg={12} size="small" />
            </MuiGrid>

            <Grid
                title={t("operacio.linies.title")}
                resourceName="article"
                columns={columns}
                readOnly
                inlineEditable={false}
                density="compact"
                fixedFilter={`combinacioArticles : false and controlExistencies : true`}
                height={400}
                paginable={false}
                defaultSortModel={[
                    {
                        field: "articleCategoria",
                        sort: "asc",
                    },
                    {
                        field: "nomComercial",
                        sort: "asc",
                    },
                ]}
            />
        </Box>
    );
};
const OperacioInventariContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "codi",
            flex: 0.4,
        },
        {
            name: "nom",
            flex: 0.8,
        },
        {
            name: "nomComercial",
            flex: 1,
        },
        {
            name: "articleCategoria",
            flex: 0.5,
        },
        {
            name: "devolucio",
            flex: 1,
            minWidth: 220,
            headerName: t(
                "articleExistencies.action.operacioInventari.quantitat"
            ),
            renderCell: (params) => (
                <CellEditComp2
                    tootlip={t(
                        "articleExistencies.action.operacioInventari.quantitat"
                    )}
                    placeholder={t(
                        "articleExistencies.action.operacioInventari.quantitatPlaceHolder"
                    )}
                    id={params.id}
                    keyVal="quantitatsLinia"
                    max={99999999999}
                    min={0}
                    step={0.001}
                />
            ),
        },
    ];

    return (
        <Box sx={{ height: "100%" }}>
            <MuiGrid container spacing={2} sx={{ my: 1 }}>
                <GridFormField name="embarcacio" lg={4} size="small" />
                <GridFormField
                    name="ruta"
                    lg={4}
                    size="small"
                    advancedSearchColumns={columnesRuta}
                />

                <GridFormField name="descripcio" lg={12} size="small" />
            </MuiGrid>

            <Grid
                title={t("operacio.linies.title")}
                resourceName="article"
                columns={columns}
                readOnly
                // perspectives={[`quantitatEmbarcacio#${data?.embarcacio}`]} TODO: S'ha de mirar si ho podem fer
                inlineEditable={false}
                density="compact"
                fixedFilter={`combinacioArticles : false and controlExistencies : true`}
                height={400}
                paginable={false}
                defaultSortModel={[
                    {
                        field: "articleCategoria",
                        sort: "asc",
                    },
                    {
                        field: "nomComercial",
                        sort: "asc",
                    },
                ]}
            />
        </Box>
    );
};
const OperacioCompraContent = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 0.4,
        },
        {
            name: "nom",
            flex: 0.8,
        },
        {
            name: "nomComercial",
            flex: 1,
        },
        {
            name: "articleCategoria",
            flex: 0.5,
        },
        {
            name: "devolucio",
            flex: 1,
            minWidth: 220,
            headerName: t("articleExistencies.action.operacioCompra.quantitat"),
            renderCell: (params) => (
                <CellEditComp2
                    tootlip={t(
                        "articleExistencies.action.operacioCompra.quantitat"
                    )}
                    placeholder={t(
                        "articleExistencies.action.operacioCompra.quantitatPlaceHolder"
                    )}
                    id={params.id}
                    keyVal="quantitatsLinia"
                    max={99999999999}
                    min={0}
                    step={0.001}
                />
            ),
        },
    ];

    return (
        <Box sx={{ height: "100%" }}>
            <MuiGrid container spacing={2} sx={{ my: 1 }}>
                <GridFormField name="embarcacio" lg={3} size="small" />
                <GridFormField
                    name="ruta"
                    lg={3.5}
                    size="small"
                    advancedSearchColumns={columnesRuta}
                />
                <GridFormField name="numeroCompra" lg={3} size="small" />
                <GridFormField
                    name="importTotal"
                    lg={2.5}
                    size="small"
                    suffix=" €"
                />
                <GridFormField name="descripcio" lg={12} size="small" />
            </MuiGrid>

            <Grid
                title={t("operacio.linies.title")}
                resourceName="article"
                columns={columns}
                readOnly
                inlineEditable={false}
                density="compact"
                fixedFilter={`combinacioArticles : false and controlExistencies : true`}
                height={400}
                paginable={false}
                defaultSortModel={[
                    {
                        field: "articleCategoria",
                        sort: "asc",
                    },
                    {
                        field: "nomComercial",
                        sort: "asc",
                    },
                ]}
            />
        </Box>
    );
};

// Formulari de creació i edició d'articles existències
const ArticleExistenciesForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField
                name="article"
                xs={12}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField name="embarcacio" xs={12} />
            <GridFormField name="quantitat" xs={12} />
        </MuiGrid>
    );
};

// Filtres de la taula
const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField
                name="article"
                lg={4}
                advancedSearchColumns={columnesArticle}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFilterField name="embarcacio" lg={4} />
            <GridFilterField name="quantitat" lg={2} />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, like } = SpringFilterQueryBuilder;

    const filter = and(
        equal("embarcacio.id", data?.embarcacio?.id),
        equal("article.id", data?.article?.id),
        like("quantitat", likeAny(data?.quantitat))
    ).toString();

    return filter;
};

const OperacioFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"E_FILTER"}
                resourceName={"articleExistencia"}
                persistentState
                springFilterBuilder={springFilterBuilder}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialOnChangeRequest
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const ArticlesExistencies = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const gridApiRef = React.useRef();

    const columns = [
        {
            name: "article",
            flex: 2,
        },
        {
            name: "embarcacio",
            flex: 2,
        },
        {
            name: "quantitat",
            flex: 1,
        },
    ];

    const toolbarAdditionalActions = [
        {
            position: 2,
            component: (
                <ActionButton
                    resourceName="articleExistencia"
                    code="E_OPERACIO_COMPRA"
                    detached
                    variant="contained"
                    formDialogSize="lg"
                    formChildren={<OperacioCompraContent />}
                    buttonComponent={IconButtonButtonComponent}
                    icon="add_shopping_cart"
                    onSuccess={() => {
                        gridApiRef?.current?.doRefresh();
                    }}
                />
            ),
        },
        {
            position: 2,
            component: (
                <ActionButton
                    resourceName="articleExistencia"
                    code="E_OPERACIO_INVENTARI"
                    detached
                    variant="contained"
                    formDialogSize="lg"
                    formChildren={<OperacioInventariContent />}
                    buttonComponent={IconButtonButtonComponent}
                    icon="rule"
                    onSuccess={() => {
                        gridApiRef?.current?.doRefresh();
                    }}
                />
            ),
        },
        {
            position: 2,
            component: (
                <ActionButton
                    resourceName="articleExistencia"
                    code="E_OPERACIO_TRASPAS"
                    detached
                    variant="contained"
                    formDialogSize="lg"
                    formChildren={<OperacioTraspasContent />}
                    buttonComponent={IconButtonButtonComponent}
                    icon="directions_boat"
                    onSuccess={() => {
                        gridApiRef?.current?.doRefresh();
                    }}
                />
            ),
        },
    ];

    return (
        <Grid
            apiRef={gridApiRef}
            title={t("articleExistencies.title")}
            resourceName="articleExistencia"
            columns={columns}
            fixedFilter={fixedFilter}
            findDisabled={!filterInitialized}
            toolbarAdditionalActions={toolbarAdditionalActions}
            toolbarAdditionalRow={
                <OperacioFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
            }
            formPopupEditable
            formPopupComponent={<ArticleExistenciesForm />}
            formPopupTitleCreate={t("articleExistencies.create")}
            formPopupTitleUpdate={t("articleExistencies.update")}
            formPopupSize="sm"
            density="compact"
        />
    );
};
export default ArticlesExistencies;
