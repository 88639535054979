import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";

const CategoriaForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="codi" disabled={data?.id != null} xs={4} />
            <GridFormField name="consumPropi" xs={4} />
            <GridFormField name="nomComercial" xs={12} />
        </MuiGrid>
    );
};

const Categories = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 0.5,
        },
        {
            name: "nomComercial",
            flex: 2,
        },
        {
            name: "consumPropi",
            flex: 0.75,
        },
    ];

    return (
        <Grid
            title={t("categories.title")}
            resourceName="articleCategoria"
            columns={columns}
            formPopupEditable
            formPopupComponent={<CategoriaForm />}
            formPopupTitleCreate={t("categories.create")}
            formPopupTitleUpdate={t("categories.update")}
        />
    );
};
export default Categories;
