import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Form, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { columnesZones } from "sharedAdvancedSearch/advancedSearchColumns";
import Opcions from "./Opcions";

const ArticleForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="codi" disabled={data?.id != null} xs={3} />
            <GridFormField name="nom" xs={9} />
            <GridFormField name="nomComercial" xs={12} />
            <GridFormField name="articleCategoria" xs={5.5} />
            <GridFormField name="iva" xs={3.25} suffix={" %"} />
            <GridFormField name="preu" xs={3.25} suffix={" €"} />
            <GridFormField name="controlExistencies" xs={4} />
            <GridFormField name="ocult" xs={4} />
            <GridFormField name="combinacioArticles" xs={4} />
        </MuiGrid>
    );
};

const ArticlesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("articles.update")}
                >
                    <Tab label={t("articles.update")} value="0" />
                    <Tab
                        label={t("opcions.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <ArticleForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <Opcions />
            </TabPanel>
        </TabContext>
    );
};

const ArticlesDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="article"
                id={id}
                titleCreate={t("articles.create")}
                titleUpdate={t("articles.update")}
                size="small"
                fixedLockValue
                onGoBack={() => navigate(id ? "../../" : "../")}
                onCreate={(data) => {
                    navigate(data.id.toString());
                }}
            >
                <ArticlesFormContent />
            </Form>
        </Box>
    );
};
export default ArticlesDetall;
