import React from "react";
import { useTranslation } from "react-i18next";
import {
    ActionButton,
    Filter,
    Grid,
    useContentDialog,
} from "@programari-limit/base-react";
import {
    Box,
    darken,
    Icon,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import MuiGrid from "@mui/material/Grid";
import GridFilterField from "components/GridFilterField";
import { IconButtonButtonComponent } from "components/ActionReportButtonComponents";
import GridFormField from "components/GridFormField";
import { columnesUsuaris } from "sharedAdvancedSearch/advancedSearchColumns";

export const getFielOrgange = (mode) => "#f9e89a";

const CopiarRutaForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="diaInici" lg={6} />
            <GridFormField name="diaFi" lg={6} />
            <GridFormField name="dilluns" lg={1.7} />
            <GridFormField name="dimarts" lg={1.7} />
            <GridFormField name="dimecres" lg={1.7} />
            <GridFormField name="dijous" lg={1.7} />
            <GridFormField name="divendres" lg={1.7} />
            <GridFormField name="dissabte" lg={1.7} />
            <GridFormField name="diumenge" lg={1.7} />
            <GridFormField name="embarcacio" lg={12} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const ContentResponseCrearMassiu = (props) => {
    const { response } = props;

    return (
        <Box>
            {response?.map((item, index) => (
                <Box
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                        <Icon color={item.creada ? "success" : "error"}>
                            {item.creada ? "check" : "close"}
                        </Icon>
                        <Typography>{item?.resultatCreacio}</Typography>
                    </Box>
                    {item?.rutaPunts?.length > 0 && (
                        <Box sx={{ display: "flex", flexWrap: "wrap", ml: 5 }}>
                            {"["}&nbsp;
                            {item?.rutaPunts?.map((punt, index) => (
                                <Typography
                                    key={index}
                                    component={"span"}
                                    sx={{
                                        mr:
                                            index < item?.rutaPunts?.length - 1
                                                ? 1
                                                : 0,
                                    }}
                                >
                                    {punt?.hora}
                                    {index < item?.rutaPunts?.length - 1
                                        ? " ,"
                                        : ""}
                                </Typography>
                            ))}
                            &nbsp;{"]"}
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};

const CrearMassiuForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="dataInici" lg={6} />
            <GridFormField name="dataFi" lg={6} />
        </MuiGrid>
    );
};

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="recurs" sm={7} md={5} lg={4} xl={3} />
            <GridFilterField name="dataInici" sm={5} md={3} lg={2} xl={2} />
            <GridFilterField
                name="dataFi"
                xs={12}
                sm={4}
                md={3}
                lg={2}
                xl={2}
            />
            {/* TODO: Fer ses modificacions pq sigui un tripulant i no un usuari */}
            <GridFilterField
                name="user"
                xs={10.5}
                sm={6.5}
                md={11}
                lg={3.5}
                xl={4.5}
                advancedSearchColumns={columnesUsuaris}
            />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const RutesFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"RT_FILTER"}
                resourceName={"ruta"}
                persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, or, ge, le } = SpringFilterQueryBuilder;

    const filter = and(
        // TODO: Posar es filtre per tripulants, semblant a operacions amb un exist
        // or(
        //     equal("patro.id", data?.user?.id),
        //     equal("mecanic.id", data?.user?.id),
        //     equal("marinerFotos.id", data?.user?.id),
        //     equal("marinerBar1.id", data?.user?.id),
        //     equal("marinerBar2.id", data?.user?.id)
        // ),
        equal("recurs.id", data?.recurs?.id),
        ge("data", data?.dataInici ? data?.dataInici : undefined),
        le("data", data?.dataFi ? data?.dataFi : undefined)
    ).toString();

    return filter;
};

const Rutes = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const gridApiRef = React.useRef();
    const columns = [
        {
            name: "data",
            flex: 0.75,
        },
        {
            name: "recurs",
            flex: 1,
        },
        {
            name: "embarcacio",
            flex: 1.5,
        },
        {
            name: "passatgeNouTotal",
            flex: 0.75,
        },
        {
            name: "incidencies",
            flex: 0.75,
        },
    ];

    const { dialog, openDialog } = useContentDialog("lg");

    const onSuccessCrearMassiuDialog = (response) => {
        openDialog(
            t("rutes.resultatsRutes"),
            null, // 'receipt_long',
            null, // 'inherit',
            <ContentResponseCrearMassiu response={response} />,
            [
                {
                    value: "close",
                    contained: false,
                    startIcon: "close",
                    description: t("comu.tancar"),
                },
            ],
            (value, close) => {
                close();
                gridApiRef?.current?.doRefresh();
            }
        );
    };

    return (
        <Box
            sx={{
                height: "100%",
                "& .cellWarning": {
                    bgcolor: (theme) => getFielOrgange(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellWarning": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFielOrgange(theme.palette.mode),
                            0.1
                        )}!important`,
                },
            }}
        >
            <Grid
                apiRef={gridApiRef}
                title={t("rutes.title")}
                resourceName="ruta"
                columns={columns}
                findDisabled={!filterInitialized}
                fixedFilter={fixedFilter}
                formRoutePath="form"
                density="compact"
                toolbarAdditionalActions={[
                    {
                        position: 2,
                        component: (
                            <ActionButton
                                resourceName="ruta"
                                code="RT_CREAR_MASSIU"
                                title={t("rutes.rutesMassives")}
                                detached
                                variant="contained"
                                formDialogSize="sm"
                                buttonComponent={IconButtonButtonComponent}
                                icon="map"
                                formChildren={<CrearMassiuForm />}
                                onSuccess={(response) => {
                                    onSuccessCrearMassiuDialog(response);
                                }}
                            />
                        ),
                    },
                ]}
                toolbarAdditionalRow={
                    <RutesFilter
                        onSpringFilterChange={setFixedFilter}
                        onFilterInitialized={() => setFilterInitialized(true)}
                    />
                }
                actionExecEnabled
                rowAdditionalActions={[
                    {
                        icon: "content_copy",
                        showInMenu: true,
                        rowLinkAction: "RT_DUPLICAR",
                        rowLinkActionOnSuccess: () =>
                            gridApiRef?.current?.doRefresh(),
                        rowLinkFormChildren: <CopiarRutaForm />,
                    },
                ]}
                defaultSortModel={[
                    {
                        field: "data",
                        sort: "desc",
                    },
                    {
                        field: "recurs",
                        sort: "desc",
                    },
                ]}
                getCellClassName={({ row, field }) => {
                    if (row?.incidencies > 0) {
                        return "cellWarning";
                    }
                    return "";
                }}
            />
            {dialog}
        </Box>
    );
};

export default Rutes;
