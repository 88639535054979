import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import {
    columnesUsuaris,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";

export const ZonesUserForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <Form
            resourceName="zonaUser"
            id={id}
            titleCreate={t("zonesUser.create")}
            titleUpdate={t("zonesUser.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="zona" lg={4} />
                <GridFormField name="user" lg={8} />
            </MuiGrid>
        </Form>
    );
};

const ZonesUser = (props) => {
    const { userId, zonaId } = props;
    const { t } = useTranslation();
    const columns = [];

    if (!zonaId) {
        columns.push({
            name: "codiZona",
            flex: 1,
            formFieldProps: {
                advancedSearchColumns: columnesZones,
            },
            editable: false
        });
        columns.push({
            name: "zona",
            flex: 3,
            formFieldProps: {
                advancedSearchColumns: columnesZones,
                advancedSearchMaxWidth: "sm",
            },
        });
    }

    if (!userId) {
        columns.push({
            name: "codiUser",
            flex: 1.5,
            formFieldProps: {
                advancedSearchColumns: columnesUsuaris,
            },
            editable: false
        });
        columns.push({
            name: "user",
            flex: 3,
            formFieldProps: {
                advancedSearchColumns: columnesUsuaris,
            },
        });
    }

    const fixedFilter = () => {
        if (zonaId) {
            return `zona.id : ${zonaId}`;
        }
        if (userId) {
            return `user.id : ${userId}`;
        }
        return undefined;
    };

    const inlineFormStaticData = () => {
        if (zonaId) {
            return { zona: { id: zonaId } };
        }
        if (userId) {
            return { user: { id: userId } };
        }
        return undefined;
    };

    return (
        <Grid
            title={!userId && !zonaId && t("zonesUser.title")}
            resourceName="zonaUser"
            columns={columns}
            fixedFilter={fixedFilter()}
            onRowDoubleClick={() => {}}
            inlineFormStaticData={inlineFormStaticData()}
            rowHideEditViewAction
            density={!userId && !zonaId ? "compact" : "standard"}
        />
    );
};

export default ZonesUser;
