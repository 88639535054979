import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Form, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Alert, Box, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ReservesDescompte from "./ReservesDescompte";
import ReservesLinia from "./ReservesLinia";
import {
    columnesAgendaProducte,
    columnesUsuaris,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";

const ReservesForm = () => {
    const { t } = useTranslation();
    return (
        <MuiGrid container spacing={2} sx={{ mt: 2 }}>
            <GridFormField
                name="agendaProducte"
                lg={4}
                advancedSearchColumns={columnesAgendaProducte}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField name="numero" lg={4} />
            <GridFormField name="data" lg={2} />
            <GridFormField name="dataReserva" lg={2} />
            <GridFormField name="codiSerie" lg={1.5} />
            <GridFormField name="numeroSerie" lg={1.5} />
            <GridFormField name="tipus" lg={2} />
            <GridFormField name="estat" lg={1.75} />
            <GridFormField
                name="user"
                lg={2.5}
                advancedSearchColumns={columnesUsuaris}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField
                name="zona"
                lg={2.5}
                advancedSearchColumns={columnesZones}
                advancedSearchMaxWidth="sm"
            />
            <GridFormField name="adults" lg={2} />
            <GridFormField name="infants" lg={2} />
            <GridFormField name="bebes" lg={2} />
            <MuiGrid item lg={6} />
            <GridFormField name="pagamentTipus" lg={2} />
            <GridFormField name="importDescomptat" lg={2} suffix={" €"} />
            <GridFormField name="importEfectiu" lg={2} suffix={" €"} />
            <GridFormField name="importTargeta" lg={2} suffix={" €"} />
            <GridFormField name="importTotal" lg={2} suffix={" €"} />
            <MuiGrid item lg={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                    {t("reserves.altres").toUpperCase()}
                </Divider>
            </MuiGrid>
            <GridFormField name="cif" lg={2} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const ReservesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            {(data?.reservaManual || data?.liniaManual) && (
                <Alert severity="warning">
                    {data?.reservaManual &&
                        data?.liniaManual &&
                        t("reserves.reservaLiniaManual")}
                    {data?.reservaManual &&
                        !data?.liniaManual &&
                        t("reserves.reservaManual")}
                    {data?.liniaManual &&
                        !data?.reservaManual &&
                        t("reserves.liniaManual")}
                </Alert>
            )}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("reserves.form.tabs")}
                >
                    <Tab label={t("reserves.update")} value="0" />
                    <Tab
                        label={t("reservesLinia.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("reservesDescompte.title")}
                        value={"2"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <ReservesForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <ReservesLinia producte={data} />
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", p: 0 }}>
                <ReservesDescompte producte={data} />
            </TabPanel>
        </TabContext>
    );
};

const ReservesDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="reserva"
                id={id}
                titleCreate={t("reserves.create")}
                titleUpdate={t("reserves.update")}
                onGoBack={() => navigate(-1)}
                size="small"
                // readOnly
                staticData={{ entradaManual: true }}
            >
                <ReservesFormContent />
            </Form>
        </Box>
    );
};
export default ReservesDetall;
