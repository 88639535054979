import React from "react";
import { useTranslation } from "react-i18next";
import { Filter, Grid } from "@programari-limit/base-react";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { likeAny } from "utils/springFilterUtils";
import MuiGrid from "@mui/material/Grid";
import GridFilterField from "components/GridFilterField";
import { columnesCentreCost } from "sharedAdvancedSearch/advancedSearchColumns";

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="codi" sm={4} md={3} lg={2} xl={1.25} />
            <GridFilterField name="nom" sm={8} md={9} lg={3.5} xl={3.5} />
            <GridFilterField name="tipus" sm={4} md={4} lg={2} xl={2} />
            <GridFilterField
                name="centreCostPare"
                xs={10}
                sm={6.5}
                md={6.5}
                lg={3.5}
                xl={3}
                advancedSearchColumns={columnesCentreCost}
            />
            <MuiGrid item md={0} lg={0} xl={1.75} />

            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const CentreCostFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"CC_FILTER"}
                resourceName={"centreCost"}
                persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, like } = SpringFilterQueryBuilder;

    const filter = and(
        like("codi", likeAny(data?.codi)),
        like("nom", likeAny(data?.nom)),
        equal("tipus", data?.tipus),
        equal("centreCostPare.id", data?.centreCostPare?.id)
    ).toString();

    return filter;
};

const CentreCost = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "tipus",
            flex: 0.75,
        },
        {
            name: "centreCostPare",
            flex: 1.5,
        },
        {
            name: "observacions",
            flex: 3,
        },
    ];

    return (
        <Grid
            title={t("centreCost.title")}
            resourceName="centreCost"
            columns={columns}
            findDisabled={!filterInitialized}
            fixedFilter={fixedFilter}
            formRoutePath="form"
            toolbarAdditionalRow={
                <CentreCostFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
            }
            defaultSortModel={[
                {
                    field: "nom",
                    sort: "desc",
                },
            ]}
        />
    );
};
export default CentreCost;
