import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { columnesSerie } from "sharedAdvancedSearch/advancedSearchColumns";

const TerminalForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="codi" xs={4} />
            <GridFormField name="nom" xs={8} />
            <GridFormField name="identificador" xs={5} />
            <GridFormField name="versio" xs={4} />
            <GridFormField
                name="serie"
                xs={3}
                advancedSearchColumns={columnesSerie}
            />
            <GridFormField name="embarcacio" xs={12} />
            <GridFormField name="observacions" xs={12} />
        </MuiGrid>
    );
};

export const Terminal = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 1.75,
        },
        {
            name: "identificador",
            flex: 1,
        },
        {
            name: "serie",
            flex: 1.5,
        },
        {
            name: "embarcacio",
            flex: 1.5,
        },
        {
            name: "versio",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("terminals.title")}
            resourceName="terminal"
            columns={columns}
            formPopupEditable
            formPopupComponent={<TerminalForm />}
            formPopupTitleCreate={t("terminals.create")}
            formPopupTitleUpdate={t("terminals.update")}
            density="compact"
        />
    );
};

export default Terminal;
