import { suffixValueFormatter } from "utils/muiUtils";

export const columnesProducte = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "zona",
        flex: 0.8,
    },
    {
        name: "tipus",
        flex: 0.8,
    },
    {
        name: "actiu",
        flex: 0.5,
    },
];
export const columnesRequisitRecurs = [
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "producte",
        flex: 1.5,
    },
];
export const columnesZones = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
];
export const columnesUsuaris = [
    {
        name: "code",
        flex: 0.8,
    },
    {
        name: "givenName",
        flex: 2,
    },
    {
        name: "familyName",
        flex: 2,
    },
    {
        name: "email",
        flex: 2,
    },
];
export const columnesAgendaProducte = [
    {
        name: "data",
        flex: 1,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "producte",
        flex: 2,
    },
    {
        name: "zona",
        flex: 2,
    },
    {
        name: "horaInici",
        flex: 1,
    },
    {
        name: "disponibilitat",
        flex: 1,
    },
];
export const columnesClients = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "tipus",
        flex: 0.8,
    },
    {
        name: "preu",
        flex: 0.8,
        type: "currency",
        currencyDecimalPlaces: 2,
        formFieldProps: {
            suffix: " €",
        },
        valueFormatter: (params) => suffixValueFormatter(params),
    },
];
export const columnesCentreCost = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "tipus",
        flex: 0.8,
    },
];
export const columnesPuntRuta = [
    {
        name: "zona",
        flex: 1,
    },
    {
        name: "hora",
        flex: 0.5,
    },
    {
        name: "total",
        flex: 0.5,
    },
    {
        name: "totalNous",
        flex: 0.5,
    },
    {
        name: "observacions",
        flex: 2,
    },
];
export const columnesRuta = [
    {
        name: "data",
        flex: 0.8,
    },
    {
        name: "recurs",
        flex: 1.5,
    },
    {
        name: "embarcacio",
        flex: 1.5,
    },
];
export const columnesSerie = [
    {
        name: "codi",
        flex: 1,
    },
    {
        name: "numeroActual",
        flex: 1,
    },
    {
        name: "activa",
        flex: 0.5,
    },
    {
        name: "descripcio",
        flex: 4,
    },
];
export const columnesArticle = [
    {
        name: "codi",
        flex: 0.5,
    },
    {
        name: "nom",
        flex: 1.5,
    },
    {
        name: "nomComercial",
        flex: 1.5,
    },
    {
        name: "articleCategoria",
        flex: 1,
    },
    {
        name: "iva",
        flex: 0.5,
        formFieldProps: {
            suffix: " %",
        },
        valueFormatter: suffixValueFormatter,
    },
    {
        name: "preu",
        flex: 0.75,
        formFieldProps: {
            suffix: " €",
        },
        valueFormatter: suffixValueFormatter,
    },
];
export const columnesOpcio = [
    {
        name: "nom",
        flex: 1.5,
    },
    {
        name: "article",
        flex: 1.5,
    },
];
export const columnesTripulant = [
    {
        name: "nom",
        flex: 1.75,
    },
    {
        name: "dataAlta",
        flex: 1,
    },
    {
        name: "dataBaixa",
        flex: 1,
    },
    {
        name: "actiu",
        flex: 1.5,
    },
    {
        name: "user",
        flex: 1.5,
    },
];
