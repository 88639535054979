import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LicenseInfo } from "@mui/x-license-pro";
import caLocale from "date-fns/locale/ca";
import esLocale from "date-fns/locale/es";
import { KeycloakAuthProvider } from "@programari-limit/base-react";
import { BaseProvider } from "@programari-limit/base-react";
import App from "./App";
import i18n from "./config/i18n/i18n";
import popupButtons from "./config/popupButtons";

LicenseInfo.setLicenseKey(
    "d7a3848ee04d821438959d61037634beTz0xMDY1ODQsRT0xNzY5Mjk5MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

const strictModeActive = process.env.NODE_ENV !== "development";

const getAdapterLocale = () => {
    const languageTwoChars = window.navigator.language.substring(0, 2);
    switch (languageTwoChars) {
        case "ca":
            return caLocale;
        case "es":
            return esLocale;
        default:
            return caLocale;
    }
};

const providers = (
    <BaseProvider
        fallbackLanguage="ca"
        allowedLanguages={["ca", "es", "en"]}
        popupButtons={popupButtons}
        i18next={i18n}
        gridDefaultToolbarType="header"
        formLockActionDisabled
        appMenus
        appMenusSessionDependent
    >
        <DndProvider backend={HTML5Backend}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getAdapterLocale()}
            >
                <App />
            </LocalizationProvider>
        </DndProvider>
    </BaseProvider>
);

const contentWithAuth = () => {
    return (
        <KeycloakAuthProvider>
            {strictModeActive ? (
                <React.StrictMode>{providers}</React.StrictMode>
            ) : (
                providers
            )}
        </KeycloakAuthProvider>
    );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BrowserRouter>{contentWithAuth()}</BrowserRouter>);
