import React from "react";
import {
    Box,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const NoRegistres = (props) => {
    const { content, title } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                p: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
        >
            {content?.length === 0 ? (
                <CircularProgress size={50} />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <Typography sx={{ p: 1, fontWeight: "bold" }}>
                        {title}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            alignContent: "center",
                            alignSelf: "center",
                        }}
                    >
                        <Typography sx={{ p: 1 }}>
                            {t("comu.noRegistres")}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const TaulaInformes = (props) => {
    const { content, title, loading, isCodiTaula = false } = props;
    const { t } = useTranslation();

    return (
        <TableContainer
            component={Paper}
            elevation={2}
            sx={{ borderRadius: 2 }}
        >
            {!loading && content ? (
                <>
                    <Table
                        sx={{ minWidth: 400 }}
                        aria-label="simple table"
                        stickyHeader
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor: "white",
                                        zIndex: 1100,
                                        fontWeight: "bold",
                                        p: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{ p: 1, fontWeight: "bold" }}
                                    >
                                        {title}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    position: "sticky",
                                    top: 55,
                                    backgroundColor: "white",
                                    zIndex: 1000,
                                }}
                            >
                                {isCodiTaula && (
                                    <TableCell sx={{ width: "20%" }}>
                                        {t("informesEmbarcacio.columnes.codi")}
                                    </TableCell>
                                )}
                                <TableCell
                                    sx={{ width: isCodiTaula ? "40%" : "50%" }}
                                >
                                    {t("informesTripulacio.columnes.nom")}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ width: isCodiTaula ? "20%" : "25%" }}
                                >
                                    {t("informesTripulacio.columnes.dies")}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ width: isCodiTaula ? "20%" : "25%" }}
                                >
                                    {t(
                                        "informesTripulacio.columnes.percentatge"
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {content?.map((item) => (
                                <TableRow
                                    key={item.codi}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    {isCodiTaula && (
                                        <TableCell component="th" scope="row">
                                            {item.codi}
                                        </TableCell>
                                    )}
                                    <TableCell component="th" scope="row">
                                        {item.nom}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.dies}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.tpc} %
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>{" "}
                </>
            ) : (
                <NoRegistres content={content} title={title} />
            )}
        </TableContainer>
    );
};
export default TaulaInformes;
