import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { columnesUsuaris } from "sharedAdvancedSearch/advancedSearchColumns";

const TripulantForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="nom" xs={7} />
            <GridFormField
                name="user"
                xs={5}
                advancedSearchColumns={columnesUsuaris}
            />
            <GridFormField name="dataAlta" xs={4} />
            <GridFormField name="dataBaixa" xs={4} />
            <GridFormField name="actiu" xs={4} />
            <GridFormField name="observacions" xs={12} />
        </MuiGrid>
    );
};

export const Tripulant = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "nom",
            flex: 1.75,
        },
        {
            name: "dataAlta",
            flex: 1,
        },
        {
            name: "dataBaixa",
            flex: 1,
        },
        {
            name: "actiu",
            flex: 1.5,
        },
        {
            name: "user",
            flex: 1.5,
        },
        {
            name: "observacions",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("tripulants.title")}
            resourceName="tripulant"
            columns={columns}
            formPopupEditable
            formPopupComponent={<TripulantForm />}
            formPopupTitleCreate={t("tripulants.create")}
            formPopupTitleUpdate={t("tripulants.update")}
            density="compact"
        />
    );
};

export default Tripulant;
