import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MuiGrid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Form, useFormContext } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { columnesCentreCost } from "sharedAdvancedSearch/advancedSearchColumns";

const CentreCostForm = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="codi" xs={6} lg={2} />
            <GridFormField name="nom" xs={6} lg={10} />
            <GridFormField name="descripcio" xs={6} lg={12} />
            <GridFormField name="tipus" xs={6} lg={3} />
            <GridFormField
                name="centreCostPare"
                xs={6}
                lg={4}
                advancedSearchColumns={columnesCentreCost}
            />
            <GridFormField name="observacions" xs={6} lg={12} />
        </MuiGrid>
    );
};

const CentreCostFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("centreCost.title")}
                >
                    <Tab label={t("centreCost.title")} value="0" />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <CentreCostForm />
            </TabPanel>
        </TabContext>
    );
};

const CentreCostDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="centreCost"
                id={id}
                titleCreate={t("centreCost.create")}
                titleUpdate={t("centreCost.update")}
                size="small"
                onGoBack={() => navigate(-1)}
            >
                <CentreCostFormContent />
            </Form>
        </Box>
    );
};
export default CentreCostDetall;
