import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Clients, { ClientsForm } from "./pages/Clients";
import Zones, { ZonesForm } from "./pages/Zones";
import ZonesUser, { ZonesUserForm } from "./pages/ZonesUser";
import Recursos, { RecursosForm } from "./pages/Recursos";
import Embarcacions, { EmbarcacionsForm } from "./pages/Embarcacions";
import Reserves from "./pages/reserves/Reserves";
import ReservesDetall from "pages/reserves/ReservesDetall";
import Productes from "./pages/productes/Productes";
import ProductesDetall from "pages/productes/ProductesDetall";
import AgendesProducte from "./pages/agendaProducte/AgendesProducte";
import AgendesProducteDetall from "pages/agendaProducte/AgendesProducteDetall";
import Users from "./pages/Users";
import NotFoundPage from "./pages/404";
import UsersDetall from "pages/UsersDetall";
import ZonesDetall from "pages/ZonesDetall";
import Parametres from "pages/Parametres";
import InformeZonaVenedor, {
    InformeZonaVenedorForm,
} from "pages/InformeZonaVenedor";
import UsRecurs from "pages/usRecurs";
import Proveidor from "pages/Proveidor";
import ProveidorDetall from "pages/ProveidorDetall";
import CentreCost from "pages/centreCost/CentreCost";
import CentreCostDetall from "pages/centreCost/CentreCostDetall";
import Rutes from "pages/rutes/Rutes";
import RutesPunt from "pages/rutes/RutesPunt";
import RutesDetall from "pages/rutes/RutesDetall";
import Serie from "pages/Serie";
import Terminal from "pages/Terminal";
import Tripulant from "pages/Tripulant";
import InformeEmbarcacio from "pages/Informes/InformeEmbarcacio";
import InformeTripulacio from "pages/Informes/InformeTripulacio";
import Articles from "pages/articles/Articles";
import Categories from "pages/Categories";
import Everet from "pages/Everet";
import ArticlesExistencies from "pages/ArticleExistencies";
import Operacio from "pages/operacio/Operacio";
import OperacioDetall from "pages/operacio/OperacioDetall";
import ArticlesDetall from "pages/articles/ArticlesDetall";
import RutaTripulant from "pages/rutes/RutesTripulant";

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
            <Route path="agendaProducte">
                <Route index element={<AgendesProducte />} />
                <Route path="form">
                    <Route index element={<AgendesProducteDetall />} />
                    <Route path=":id" element={<AgendesProducteDetall />} />
                </Route>
            </Route>
            <Route path="article">
                <Route index element={<Articles />} />
                <Route path="form">
                    <Route index element={<ArticlesDetall />} />
                    <Route path=":id" element={<ArticlesDetall />} />
                </Route>
            </Route>
            <Route path="articleCategoria">
                <Route index element={<Categories />} />
            </Route>
            <Route path="articleExistencia">
                <Route index element={<ArticlesExistencies />} />
            </Route>
            <Route path="centreCost">
                <Route index element={<CentreCost />} />
                <Route path="form">
                    <Route index element={<CentreCostDetall />} />
                    <Route path=":id" element={<CentreCostDetall />} />
                </Route>
            </Route>
            <Route path="client">
                <Route index element={<Clients />} />
                <Route path="form">
                    <Route index element={<ClientsForm />} />
                    <Route path=":id" element={<ClientsForm />} />
                </Route>
            </Route>
            <Route path="embarcacio">
                <Route index element={<Embarcacions />} />
                <Route path="form">
                    <Route index element={<EmbarcacionsForm />} />
                    <Route path=":id" element={<EmbarcacionsForm />} />
                </Route>
            </Route>
            <Route path="everet">
                <Route index element={<Everet />} />
            </Route>
            <Route path="informeEmbarcacio">
                <Route index element={<InformeEmbarcacio />} />
            </Route>
            <Route path="informeTripulacio">
                <Route index element={<InformeTripulacio />} />
            </Route>
            <Route path="informeZonaVenedor">
                <Route index element={<InformeZonaVenedor />} />
                <Route path="form">
                    <Route index element={<InformeZonaVenedorForm />} />
                    <Route path=":id" element={<InformeZonaVenedorForm />} />
                </Route>
            </Route>
            <Route path="operacio">
                <Route index element={<Operacio />} />
                <Route path="form">
                    <Route index element={<OperacioDetall />} />
                    <Route path=":id" element={<OperacioDetall />} />
                </Route>
            </Route>
            <Route path="parametre">
                <Route index element={<Parametres />} />
            </Route>
            <Route path="producte">
                <Route index element={<Productes />} />
                <Route path="form">
                    <Route index element={<ProductesDetall />} />
                    <Route path=":id" element={<ProductesDetall />} />
                </Route>
            </Route>
            <Route path="proveidor">
                <Route index element={<Proveidor />} />
                <Route path="form">
                    <Route index element={<ProveidorDetall />} />
                    <Route path=":id" element={<ProveidorDetall />} />
                </Route>
            </Route>
            <Route path="recurs">
                <Route index element={<Recursos />} />
                <Route path="form">
                    <Route index element={<RecursosForm />} />
                    <Route path=":id" element={<RecursosForm />} />
                </Route>
            </Route>
            <Route path="reserva">
                <Route index element={<Reserves />} />
                <Route path="form">
                    <Route index element={<ReservesDetall />} />
                    <Route path=":id" element={<ReservesDetall />} />
                </Route>
            </Route>
            <Route path="ruta">
                <Route index element={<Rutes />} />
                <Route path="form">
                    <Route index element={<RutesDetall />} />
                    <Route path=":id" element={<RutesDetall />} />
                </Route>
            </Route>
            <Route path="rutaPunt">
                <Route index element={<RutesPunt />} />
            </Route>
            <Route path="rutaTripulant">
                <Route index element={<RutaTripulant />} />
            </Route>
            <Route path="serie">
                <Route index element={<Serie />} />
            </Route>
            <Route path="terminal">
                <Route index element={<Terminal />} />
            </Route>
            <Route path="tripulant">
                <Route index element={<Tripulant />} />
            </Route>
            <Route path="user">
                <Route index element={<Users />} />
                <Route path="form">
                    <Route index element={<UsersDetall />} />
                    <Route path=":id" element={<UsersDetall />} />
                </Route>
            </Route>
            <Route path="usRecurs" element={<UsRecurs />} />
            <Route path="zona">
                <Route index element={<Zones />} />
                <Route path="form">
                    <Route index element={<ZonesDetall />} />
                    <Route path=":id" element={<ZonesDetall />} />
                </Route>
            </Route>
            <Route path="zonaUser">
                <Route index element={<ZonesUser />} />
                <Route path="form">
                    <Route index element={<ZonesUserForm />} />
                    <Route path=":id" element={<ZonesUserForm />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRoutes;
