import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";

const SerieForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="codi" disabled={data?.id != null} xs={4} />
            <GridFormField name="numeroActual" xs={4} />
            <GridFormField name="activa" xs={4} />
            <GridFormField name="descripcio" xs={12} />
        </MuiGrid>
    );
};

export const Serie = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "numeroActual",
            flex: 1,
        },
        {
            name: "activa",
            flex: 0.5,
        },
        {
            name: "descripcio",
            flex: 4,
        },
    ];

    return (
        <Grid
            title={t("series.title")}
            resourceName="serie"
            columns={columns}
            formPopupEditable
            formPopupComponent={<SerieForm />}
            formPopupTitleCreate={t("series.create")}
            formPopupTitleUpdate={t("series.update")}
        />
    );
};

export default Serie;
